import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useCustomerId } from 'src/hooks/useCustomerId';
import useIsProcessing from 'src/lib/utils/hooks/useIsProcessing';
import { Radio } from '../../components/Radio';
import { useLazyGetSimQuery, useSetTrafficPolicyMutation } from '../simcards-api-slice';

const TrafficPolicyModal = ({ isOpen, options, handleClose, iccid, currentPolicy }) => {
  const customerId = useCustomerId();
  //setTrafficPolicy,
  const [getSim, getSimResponse] = useLazyGetSimQuery();
  const [setTrafficPolicy, setTrafficPolicyResponse] = useSetTrafficPolicyMutation();

  const [selectedOption, setSelectedOption] = useState(currentPolicy);
  const handleApply = async () => {
    await setTrafficPolicy({
      iccids: [iccid],
      policy: selectedOption,
    });
    if (customerId) {
      await getSim({ iccid });
    }
    handleClose();
  };

  const isProcessing = useIsProcessing([setTrafficPolicyResponse.isLoading, getSimResponse.isFetching]);
  return (
    <Modal dialogClassName="w-30" centered show={isOpen}>
      <Modal.Body>
        <div>
          <h3 className="header-title pb-3">Select traffic policy</h3>
          {options &&
            options.map((option) => (
              <Radio
                key={option}
                label={option}
                onClick={() => setSelectedOption(option)}
                checked={selectedOption === option}
              />
            ))}
          <div className="d-flex justify-content-end mt-4">
            <Button className="me-2 w-30" variant="white" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="w-30"
              variant="light-primary"
              type="submit"
              onClick={handleApply}
              disabled={!selectedOption}
            >
              {isProcessing ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </>
              ) : (
                'Apply'
              )}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TrafficPolicyModal;
