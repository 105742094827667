import { PhoneNumberUtil } from 'google-libphonenumber';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

export const isValidPhoneNumber = (phoneNumber: string, countryCode: CountryCode): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parse(phoneNumber, countryCode);
    return phoneUtil.isValidNumber(number);
  } catch (error) {
    console.error('Parsing error:', error);
    return false;
  }
};

export const formatPhoneNumberE164 = (phoneNumber: string, countryCode: CountryCode) => {
  const parsed = parsePhoneNumberFromString(phoneNumber, countryCode);
  return parsed ? parsed.format('E.164') : phoneNumber;
};
