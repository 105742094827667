import * as Sentry from '@sentry/react';
import { AuthTokens, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';

export const getAuthenticatedUser = async (
  forceRefresh?: boolean,
): Promise<{
  username: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  customer_id: string;
  session: AuthTokens;
  emailVerified: boolean;
  accessToken: string;
}> => {
  try {
    const user = await getCurrentUser();
    const { tokens: session } = await fetchAuthSession({ forceRefresh });

    // Note that session will no longer contain refreshToken and clockDrift

    if (session) {
      return {
        username: user?.username,
        email: session.idToken.payload.email as string,
        name: session.idToken.payload.name as string,
        given_name: session.idToken.payload.given_name as string,
        family_name: session.idToken.payload.family_name as string,
        session,
        customer_id: session.idToken.payload['custom:customer_id'] as string,
        emailVerified: session.idToken.payload.email_verified as boolean,
        accessToken: session.accessToken.toString(),
      };
    } else {
      return null;
    }
  } catch (error) {
    Sentry.captureException(error);
    console.warn('Failed to fetch authenticated user:', error);
    return null;
  }
};

// export const handleUpdateUserAttribute = async (attributeKey: string, value: string) => {
//   try {
//     const output = await updateUserAttribute({
//       userAttribute: {
//         attributeKey,
//         value,
//       },
//     });
//     handleUpdateUserAttributeNextSteps(output);
//     return output;
//   } catch (error) {
//     console.log(error);
//   }
// };

// function handleUpdateUserAttributeNextSteps(output: UpdateUserAttributeOutput) {
//   const { nextStep } = output;

//   switch (nextStep.updateAttributeStep) {
//     case "CONFIRM_ATTRIBUTE_WITH_CODE":
//       // eslint-disable-next-line no-case-declarations
//       const codeDeliveryDetails = nextStep.codeDeliveryDetails;
//       console.log(`Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`);
//       // Collect the confirmation code from the user and pass to confirmUserAttribute.
//       break;
//     case "DONE":
//       console.log(`attribute was successfully updated.`);
//       break;
//   }
// }
