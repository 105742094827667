export const validatePassword = (password: string) => {
  if (password) {
    if (!/[0-9]+/.test(password)) {
      return 'settings.profile.password.number';
    } else if (!/[A-Z]+/.test(password)) {
      return 'settings.profile.password.upperCase';
    } else if (!/[a-z]+/.test(password)) {
      return 'settings.profile.password.lowerCase';
    } else if (!/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(password)) {
      return 'settings.profile.password.specialCharacter';
    } else if (password.length < 8) {
      return 'settings.profile.password.8Characters';
    } else {
      return '';
    }
  } else return '';
};
