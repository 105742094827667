import TwoFactorLogo from '@assets/img/icons/2fa.svg';
import SuccessShield from '@assets/img/icons/success-shield.svg';
import { AlternateModal } from '@components/Atoms/AlternateModal/AlternateModal';
import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { LoadingModal } from '@components/Molecules/LoadingModal/LoadingModal';
import { getAuthenticatedUser } from '@modules/auth/utils';
import { setUpTOTP, updateMFAPreference, verifyTOTPSetup } from 'aws-amplify/auth';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from 'src/lib/utils/utils';

export const TwoFactorAuthentication = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [qrCodeString, setQrCodeString] = useState<string | undefined>(undefined);
  const [sharedSecret, setSharedSecret] = useState<string | null>(null);
  const [stepOneOpen, setStepOneOpen] = useState<boolean>(false);
  const [stepTwoOpen, setStepTwoOpen] = useState<boolean>(false);
  const [verificationSuccess, setVerificationSuccess] = useState<boolean>(false);
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const [verificationCodeDigits, setVerificationCodeDigits] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const details = await setUpTOTP();
        setSharedSecret(details.sharedSecret);
        const url =
          'otpauth://totp/' +
          (await getAuthenticatedUser()).email +
          '?secret=' +
          details.sharedSecret +
          '&issuer=' +
          'Simbase';
        setQrCodeString(url);
      } catch (error) {
        console.error('Error setting up TOTP:', error);
        setError(true);
        setLoadingMessage(t('errorMessage.somethingWentWrongGetInTouch'));
        setShowLoadingModal(true);
      }
    })();
  }, []);

  const handleChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) {
      return;
    }

    const newDigits = [...verificationCodeDigits];
    newDigits[index] = value;
    setVerificationCodeDigits(newDigits);

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && verificationCodeDigits[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === 'ArrowRight' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text').trim();
    if (/^\d{6}$/.test(pastedData)) {
      const newDigits = pastedData.split('');
      setVerificationCodeDigits(newDigits);
      inputRefs.current[5]?.focus();
    }
  };

  const handleMfaVerification = async () => {
    setIsLoading(true);
    setShowLoadingModal(true);
    const code = verificationCodeDigits.join('');
    try {
      setLoadingMessage(t('signup.verifyingTwoFactor'));
      await verifyTOTPSetup({ code });
      await updateMFAPreference({ totp: 'PREFERRED' });
      setVerificationSuccess(true);
      setShowLoadingModal(false);
    } catch (error) {
      setError(true);
      setLoadingMessage(t('errorMessage.somethingWentWrongGetInTouch'));
      setShowLoadingModal(true);
      if (process.env.NODE_ENV === 'development') {
        console.error('TOTP verification error:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleProceedToStepTwo = () => {
    setStepOneOpen(false);
    setStepTwoOpen(true);
  };

  const handleCloseLoadingModal = () => {
    setShowLoadingModal(false);
    setTimeout(() => {
      setError(false);
      setLoadingMessage('');
    }, 300);
  };

  return (
    <div className="tw-flex tw-min-h-screen tw-w-full tw-items-center tw-justify-center tw-bg-sb-blue-grey-100 tw-p-5 sm:tw-px-2">
      <div className="xs:tw-px-8 tw-flex tw-w-full tw-max-w-[560px] tw-flex-col tw-items-center tw-gap-14 tw-rounded-[32px] tw-border tw-border-solid tw-border-sb-blue-100 tw-bg-white tw-px-4 tw-py-10 tw-shadow-sign-up-box sm:tw-px-16">
        <img src={TwoFactorLogo} alt={t('signup.2fa.twoFactor')} className="tw-w-full" />
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
          <span className="tw-text-center tw-font-cal-sans tw-text-4xl tw-font-semibold tw-text-sb-blue-300">
            {t('signup.keepYourAccountSafe')}
          </span>
          <span className="tw-text-center tw-text-xl">{t('signup.secureYourAccountWithMultiFactor')}</span>
        </div>
        <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-5">
          <Button size="large" className="tw-w-full" onClick={() => setStepOneOpen(true)}>
            {t('signup.setupMFA')}
          </Button>
          <Button
            variant="newUnderline"
            className="tw-w-full tw-capitalize"
            onClick={() => navigate('/dashboard/home')}
            size="large"
          >
            {t('skip')}
          </Button>
        </div>
      </div>

      {stepOneOpen && (
        <AlternateModal
          modalOpen={stepOneOpen}
          closeModal={() => setStepOneOpen(false)}
          title={t('signup.mfa.stepOneTitle')}
          modalSize="large"
          showClose
        >
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-8 tw-bg-white tw-p-6">
            <span className="tw-inline-block tw-text-center">
              <Trans
                i18nKey="signup.2fa.youWillNeedATwoFactorAuthenticationApp"
                components={{
                  customLink: (
                    <a href="https://bitwarden.com/products/authenticator/" target="_blank" rel="noopener noreferrer" />
                  ),
                }}
              />
            </span>
            <div className="d-flex align-items-center justify-content-center">
              {!!qrCodeString && <QRCodeCanvas value={qrCodeString} size={128} />}
            </div>
            <span className="tw-text-center">{t('signup.2fa.cantScanQRCode')}</span>
            <div className="tw-flex tw-max-w-[100%] tw-items-center tw-justify-between tw-gap-2">
              <div className="tw-max-w-[100%] tw-truncate tw-whitespace-nowrap tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-px-3 tw-py-2">
                <span className="tw-text-base tw-text-sb-blue-700">{sharedSecret}</span>
              </div>
              <Button variant="stripped" onClick={() => copyToClipboard(sharedSecret, 'MFA Code')}>
                <div className="tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-bg-sb-blue-grey-100 tw-p-3 active:tw-bg-sb-blue-grey-300">
                  <Icon name="square_copy" />
                </div>
              </Button>
            </div>
            <Button size="large" className="tw-w-full" onClick={handleProceedToStepTwo}>
              {t('signup.mfa.enterVerificationCode')}
            </Button>
          </div>
        </AlternateModal>
      )}
      {stepTwoOpen && (
        <AlternateModal
          modalOpen={stepTwoOpen}
          closeModal={() => setStepTwoOpen(false)}
          title={t('signup.mfa.stepTwoTitle')}
          modalSize="medium"
          className="!tw-max-w-[595px]"
          showClose
        >
          {verificationSuccess ? (
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-5 tw-bg-white tw-py-11">
              <img src={SuccessShield} alt={t('Success')} />
              <div className="tw-max-w-[300px] tw-text-center">
                <span className="tw-font-cal-sans tw-text-2xl tw-font-semibold">{t('signup.2fa.mfaEnabled')}</span>
              </div>
            </div>
          ) : (
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-8 tw-bg-white tw-p-6">
              <span className="tw-text-center">{t('signup.2fa.enter6DigitCode')}</span>
              <form
                id="mfa-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleMfaVerification();
                }}
                className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4"
              >
                <div className="xs:tw-flex-row tw-flex tw-flex-col tw-items-center">
                  <div className="tw-flex tw-gap-2">
                    {verificationCodeDigits.slice(0, 3).map((digit, i) => (
                      <input
                        key={i}
                        ref={(el) => (inputRefs.current[i] = el)}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={1}
                        className="focus:tw-shadow-input tw-h-14 tw-w-12 tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-text-center tw-text-xl focus:tw-border-sb-blue-300 focus:tw-outline-none"
                        value={digit}
                        onChange={(e) => handleChange(i, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(i, e)}
                        onPaste={i === 0 ? handlePaste : undefined}
                      />
                    ))}
                  </div>
                  {/* SVG Separator */}
                  <div className="tw-mx-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="2" viewBox="0 0 17 2" fill="none">
                      <path d="M1.75 1H15.25" stroke="#DBE4F6" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                  </div>
                  {/* Last 3 Inputs */}
                  <div className="tw-flex tw-gap-2">
                    {verificationCodeDigits.slice(3, 6).map((digit, i) => (
                      <input
                        key={i + 3}
                        ref={(el) => (inputRefs.current[i + 3] = el)}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={1}
                        className="focus:tw-shadow-input tw-h-14 tw-w-12 tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-text-center tw-text-xl focus:tw-border-sb-blue-300 focus:tw-outline-none"
                        value={digit}
                        onChange={(e) => handleChange(i + 3, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(i + 3, e)}
                        onPaste={i + 3 === 0 ? handlePaste : undefined}
                      />
                    ))}
                  </div>
                </div>
              </form>
            </div>
          )}

          <div className="tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-px-6 tw-py-4">
            {verificationSuccess ? (
              <Button
                size="large"
                className="tw-w-full"
                disabled={isLoading}
                onClick={() => navigate('/dashboard/home')}
              >
                {t('Done')}
              </Button>
            ) : (
              <Button type="submit" size="large" form="mfa-form" className="tw-w-full" disabled={isLoading}>
                {isLoading ? t('loading') : t('signup.mfa.completeMultiFactor')}
              </Button>
            )}
          </div>
        </AlternateModal>
      )}
      <LoadingModal
        modalOpen={showLoadingModal}
        loadingMessage={loadingMessage}
        error={error}
        onClose={handleCloseLoadingModal}
      />
    </div>
  );
};
