import rechargeByInvoice from '@assets/img/rechargeByInvoice.png';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { useGetBillingBalanceQuery } from '../../billing-api-slice';
import RechargeByInvoice from '../modals/RechargeByInvoice';

const RechargeByInvoiceCard = () => {
  const customerId = useCustomerId();
  const [rechargeByInvoiceModalOpen, setRechargeByInvoiceModalOpen] = useState<boolean>(false);
  const balanceResponse = useGetBillingBalanceQuery(undefined, { skip: !customerId });
  const { currency_symbol: currencySymbol } = balanceResponse.data ?? {}; // renaming vars to fit current code

  return (
    <>
      <Card className="w-100">
        <Card.Header>
          <h4 className="card-header-title text-capitalize">Recharge by invoice</h4>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center h-100">
            <div className="me-3">
              <img src={rechargeByInvoice} alt="invoice-recharge" height={64} />
            </div>
            <div className="d-flex flex-column justify-content-between h-100 mx-3">
              <div className="text-body">
                Request an invoice and pay by bank transfer. The balance updates upon receiving the invoice amount.
              </div>
              <div>
                <span>
                  <span className="me-1 text-success">●</span>
                  Available
                </span>
              </div>
            </div>
            <div className="ms-3">
              <Button
                className="text-nowrap"
                size="sm"
                variant="white"
                onClick={() => setRechargeByInvoiceModalOpen(true)}
              >
                Recharge By Invoice
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <RechargeByInvoice
        currencySign={currencySymbol}
        isOpen={rechargeByInvoiceModalOpen}
        handleClose={() => setRechargeByInvoiceModalOpen(false)}
      />
    </>
  );
};

export default RechargeByInvoiceCard;
