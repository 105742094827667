import bouncingSIMCard from '@assets/img/icons/bouncingSIMCard.svg';
import simCardErrorV1 from '@assets/img/icons/simCardErrorV1.svg';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { FC, Fragment } from 'react';
import { createPortal } from 'react-dom';

type LoadingModalProps = {
  modalOpen: boolean;
  loadingMessage?: string;
  error?: boolean;
  onClose?: () => void;
};

export const LoadingModal: FC<LoadingModalProps> = ({ modalOpen, loadingMessage = '', error = false, onClose }) => {
  const modalContent = (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto"
        onClose={error ? () => onClose() : () => {}}
      >
        <div
          className="tw-fixed tw-inset-0 tw-overflow-visible tw-bg-[#7897BA66] tw-backdrop-blur-md"
          aria-hidden="true"
        />
        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
          <TransitionChild
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <DialogPanel className="tw-relative tw-flex tw-flex-col tw-gap-4" onClick={(e) => e.stopPropagation()}>
              {error ? (
                <div className="tw-flex tw-max-w-96 tw-flex-col tw-gap-9">
                  <img src={simCardErrorV1} alt="Error..." className="tw-mx-auto tw-my-4" width={100} height={100} />
                  <span className="tw-text-xl">{loadingMessage}</span>
                </div>
              ) : (
                <div className="tw-flex tw-max-w-96 tw-flex-col tw-gap-9">
                  <img src={bouncingSIMCard} alt="Loading..." className="tw-mx-auto tw-my-4" width={100} height={100} />
                  <span className="tw-text-xl">{loadingMessage}</span>
                </div>
              )}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
  return createPortal(modalContent, document.body);
};
