import rawCountries from '@core/constants/rawCountries';
import { CountryCode } from 'libphonenumber-js';

type CountryFromRaw = {
  name: string;
  countryCode: CountryCode;
  dialCode: string;
};

function useCountries(): CountryFromRaw[] {
  const countries = rawCountries.map((item) => {
    const [name, , iso2, dial] = item;
    return {
      name: name,
      countryCode: iso2.toUpperCase() as CountryCode,
      dialCode: `+${dial}`,
    };
  });

  return countries;
}

export default useCountries;
