import { LoadingModal } from '@components/Molecules/LoadingModal/LoadingModal';
import { useCheckSignUpMutation } from '@modules/dashboard/account/account-api-slice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ConfirmAccount = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [checkSignUp] = useCheckSignUpMutation();

  const email = searchParams.get('email') || '';
  const token = searchParams.get('token') || '';

  useEffect(() => {
    const verifySignUp = async () => {
      if (!email || !token) {
        navigate('/auth/signup?error=signup.missingEmailOrToken');
        return;
      }

      try {
        const response = await checkSignUp({ email, token }).unwrap();
        if (response.valid) {
          navigate(`/auth/signup/create?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`);
        } else {
          navigate('/auth/signup?error=signup.invalidOrExpiredConfirmationLink');
        }
      } catch (error) {
        console.error('Error verifying signup:', error);
        navigate('/auth/signup?error=signup.anErrorOccurredWhileVerifyingYourAccount');
      }
    };

    verifySignUp();
  }, [email, token, checkSignUp, navigate]);

  return (
    <div className="tw-flex tw-min-h-screen tw-w-full tw-items-center tw-justify-center tw-bg-sb-blue-grey-100">
      <LoadingModal modalOpen={true} loadingMessage={t('signup.verifyingYourAccount')} />
    </div>
  );
};

export default ConfirmAccount;
