import Logo from '@assets/img/icons/logo-notext.svg';
import { Button } from '@components/Atoms/Button/Button';
import { IndeterminateCheckbox } from '@components/Atoms/IndeterminateCheckbox/IndeterminateCheckbox';
import { PhoneInput, type Country } from '@components/Atoms/PhoneInput/PhoneInput';
import { LoadingModal } from '@components/Molecules/LoadingModal/LoadingModal';
import { TERMS_OF_USE } from '@core/constants/links';
import { useCheckSignUpMutation, useLazyGetSelfQuery } from '@modules/dashboard/account/account-api-slice';
import { TransparentInput } from '@modules/dashboard/simCard';
import * as Sentry from '@sentry/react';
import { getBrowserCountryCode } from '@utils/getBrowserCountryCode/getBrowserCountryCode';
import { looksLikeEmail } from '@utils/looksLikeEmail/looksLikeEmail';
import { formatPhoneNumberE164, isValidPhoneNumber } from '@utils/phoneUtils/phoneUtils';
import { validatePassword } from '@utils/validatePassword/validatePassword';
import { signIn, signUp } from 'aws-amplify/auth';
import { CountryCode } from 'libphonenumber-js';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const CreateAccount = () => {
  const { t } = useTranslation();
  const [browserCountryCode, setBrowserCountryCode] = useState('US');
  const [passwordReadOnly, setPasswordReadOnly] = useState(true);

  const [checkSignUp] = useCheckSignUpMutation();
  const [triggerGetSelf, { data: selfData, error: getSelfError }] = useLazyGetSelfQuery();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token') || '';
  const emailParam = searchParams.get('email') || '';

  const [processing, setProcessing] = useState<boolean>(false);
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState<boolean>(false);
  const handleCloseLoadingModal = () => {
    setShowLoadingModal(false);
    setTimeout(() => {
      setLoadingMessage('');
      setError(false);
    }, 300);
  };

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<CountryCode | null>(null);
  const [registerPhone, setRegisterPhone] = useState<boolean>(false);
  const [accpetedTAndC, setAcceptedTAndC] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [matchingPassword, setMatchingPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>('');
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState<boolean>(false);
  const [showInvalidPhoneError, setShowInvalidPhoneError] = useState<boolean>(false);

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      firstNameRef.current?.focus();
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (looksLikeEmail(lastName)) {
        setLastName('');
      }
    }, 0);
  }, [lastName]);

  setTimeout(() => {
    const inputEl = lastNameRef.current;
    if (inputEl && inputEl.value.includes('@')) {
      inputEl.value = '';
      setLastName('');
    }

    const interval = setInterval(() => {
      if (inputEl?.value.includes('@')) {
        inputEl.value = '';
        setLastName('');
      }
    }, 500);

    return () => clearInterval(interval);
  }, 200);

  useEffect(() => {
    setBrowserCountryCode(getBrowserCountryCode());
  }, []);

  const [triedToSubmit, setTriedToSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (password.length) {
      setMatchingPassword(password === confirmPassword);
    } else {
      setMatchingPassword(false);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    setDisableSubmit(
      !firstName ||
        !lastName ||
        !password ||
        password !== confirmPassword ||
        !accpetedTAndC ||
        !isPhoneValid ||
        !!passwordError,
    );
  }, [firstName, lastName, password, confirmPassword, accpetedTAndC, isPhoneValid, passwordError]);

  useEffect(() => {
    if (!registerPhone && !phoneNumber) {
      setShowInvalidPhoneError(false);
    }
    if (selectedCountry) {
      const valid = isValidPhoneNumber(phoneNumber, selectedCountry);
      setIsPhoneValid(valid);
      setShowInvalidPhoneError(false);
    } else if (registerPhone && !selectedCountry) {
      setIsPhoneValid(false);
    } else {
      setIsPhoneValid(true);
    }
  }, [phoneNumber, registerPhone, selectedCountry, triedToSubmit]);

  const handleSignUp = async () => {
    setProcessing(true);
    setShowLoadingModal(true);

    try {
      setLoadingMessage(t('signup.performingCheck'));
      await checkSignUp({ email: emailParam, token }).unwrap();
    } catch (err) {
      Sentry.captureException(err);
      console.error('checkSignUp failed:', err);
      setProcessing(false);
      navigate('/auth/signup?error=signup.checkSignUpError');
      return;
    }

    try {
      const name = `${firstName} ${lastName}`.trim();

      const userAttributes = {
        email: emailParam.toLowerCase(),
        name,
        given_name: firstName.trim(),
        family_name: lastName.trim(),
      };

      const clientMetadata: Record<string, string> = {
        registerPhone: registerPhone.toString(),
      };

      if (registerPhone && phoneNumber && selectedCountry) {
        const formattedPhoneNumber = formatPhoneNumberE164(phoneNumber, selectedCountry);
        clientMetadata.phone_number = formattedPhoneNumber;
      }

      if (token) {
        clientMetadata.token = token;
      }

      setLoadingMessage(t('signup.signingUp'));
      await signUp({
        username: emailParam.toLowerCase(),
        password,
        options: {
          userAttributes,
          clientMetadata,
        },
      });

      try {
        setLoadingMessage(t('signup.signIn'));
        const signInResponse = await signIn({
          username: emailParam.toLowerCase(),
          password,
        });

        if (signInResponse.isSignedIn) {
          triggerGetSelf();
        } else {
          navigate('/auth/signin');
        }
      } catch (signInError) {
        Sentry.captureException(signInError);
        console.error('Error signing in after sign up:', signInError);
        navigate('/auth/signin?error=signup.accountCreatedSignInError');
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error signing up:', error);
      navigate('/auth/signup?error=errorMessage.somethingWentWrongGetInTouch');
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (selfData) {
      navigate('/auth/signup/2fa');
    }

    if (getSelfError) {
      Sentry.captureException(getSelfError);
      console.error('Error fetching user data:', getSelfError);
      setShowLoadingModal(true);
      setError(true);
      setLoadingMessage(t('signup.failedToRetrieveUserInformation'));
    }
  }, [selfData, getSelfError, navigate]);

  useEffect(() => {
    const passwordError = validatePassword(password);
    setPasswordError(t(passwordError));
  }, [password]);

  return (
    <div className="tw-flex tw-min-h-screen tw-w-full tw-items-center tw-justify-center tw-bg-sb-blue-grey-100 [@media(max-height:710px)]:tw-pt-2.5">
      <div className="tw-flex tw-flex-col tw-gap-14 tw-px-2">
        <div className="tw-flex tw-justify-center">
          <img src={Logo} alt={'Logo'} className="tw-h-14 tw-w-44" />
        </div>

        <div className="tw-flex tw-w-full tw-flex-col tw-gap-10 tw-rounded-[32px] tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-4 tw-py-11 xs:tw-px-16 sm:tw-w-[529px]">
          <div className="tw-flex tw-flex-col tw-items-center">
            <div className="tw-flex tw-justify-center tw-pt-2.5">
              <span className="tw-text-center tw-font-cal-sans tw-text-4xl tw-font-semibold tw-text-sb-blue-300">
                {t('signup.createYourAccount')}
              </span>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (disableSubmit || processing) {
                setTriedToSubmit(true);
              } else {
                handleSignUp();
              }
            }}
            className="tw-flex tw-flex-col tw-gap-14"
            autoComplete="off"
          >
            <div className="tw-flex tw-flex-col tw-gap-5">
              {triedToSubmit && disableSubmit && (
                <div className="tw-flex tw-h-[52px] tw-items-center tw-justify-center tw-rounded-md tw-bg-sb-orange-100 tw-px-4">
                  {!firstName || !lastName || !password || !confirmPassword || (!phoneNumber && registerPhone) ? (
                    <div className="tw-inline-block">
                      <span className="tw-text-sm tw-font-semibold tw-text-sb-orange-700">{t('oops')}&nbsp;</span>
                      <span className="tw-text-sm tw-font-normal tw-text-sb-orange-700">
                        {t('signup.missingDetails')}
                      </span>
                    </div>
                  ) : !!passwordError || password !== confirmPassword ? (
                    <div className="tw-inline-block">
                      <span className="tw-text-sm tw-font-semibold tw-text-sb-orange-700">{t('oops')}&nbsp;</span>
                      <span className="tw-text-sm tw-font-normal tw-text-sb-orange-700">
                        {passwordError ? passwordError : t('signup.passwordNotMatching')}
                      </span>
                    </div>
                  ) : (
                    <div className="tw-flex tw-gap-1 !tw-text-sb-orange-700">
                      <Trans i18nKey="signup.dontForgetToAcceptTermsOfUse" />
                    </div>
                  )}
                </div>
              )}
              <div className="tw-flex tw-flex-col tw-gap-5 xs:tw-flex-row">
                <TransparentInput
                  placeholder={t('signup.firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={`tw-rounded-md tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-bg-white focus:tw-shadow-input ${triedToSubmit && !firstName ? '!tw-border-sb-orange-400 tw-shadow-missing-input-data focus:!tw-border-transparent focus:tw-shadow-none' : ''}`}
                  tabIndex={1}
                  autoComplete="given-name"
                  inputRef={firstNameRef}
                  readOnly
                  onFocus={(e) => {
                    e.target.readOnly = false;
                    setPasswordReadOnly(false);
                  }}
                />
                <TransparentInput
                  placeholder={t('signup.lastName')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={`tw-rounded-md tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-bg-white focus:tw-shadow-input ${triedToSubmit && !lastName ? '!tw-border-sb-orange-400 tw-shadow-missing-input-data focus:!tw-border-transparent focus:tw-shadow-none' : ''}`}
                  tabIndex={2}
                  autoComplete="family-name"
                  inputRef={lastNameRef}
                  readOnly={passwordReadOnly}
                />
              </div>
              <TransparentInput
                placeholder=""
                value={emailParam}
                type="email"
                onChange={() => {}}
                className="tw-rounded-md tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-border-sb-blue-grey-300"
                autoComplete="email"
                name="email"
                readOnly={true}
              />
              <div className="tw-flex tw-flex-col tw-gap-2.5">
                <TransparentInput
                  placeholder={t('signup.password')}
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onBlur={() => setShowPasswordError(true)}
                  icon={matchingPassword && 'double_tick'}
                  className={`tw-rounded-md tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-bg-white focus:tw-shadow-input ${triedToSubmit && (!password || !matchingPassword) ? '!tw-border-sb-orange-400 tw-shadow-missing-input-data focus:!tw-border-transparent focus:tw-shadow-none' : ''}`}
                  toggleVisibility={true}
                  onToggle={() => setShowPassword((prev) => !prev)}
                  tabIndex={3}
                  autoComplete="new-password"
                  name="password"
                  iconClasses="!tw-bg-transparent"
                  readOnly={passwordReadOnly}
                />

                {showPasswordError && passwordError && (
                  <div className="tw-mt-1 tw-text-sm tw-text-red-500">{passwordError}</div>
                )}
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2.5">
                <TransparentInput
                  placeholder={t('signup.confirmPassword')}
                  value={confirmPassword}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={() => {
                    if (password && confirmPassword) setShowConfirmPasswordError(true);
                  }}
                  icon={matchingPassword && 'double_tick'}
                  className={`tw-rounded-md tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-bg-white focus:tw-shadow-input ${triedToSubmit && (!confirmPassword || !matchingPassword) ? '!tw-border-sb-orange-400 tw-shadow-missing-input-data focus:!tw-border-transparent focus:tw-shadow-none' : ''}`}
                  toggleVisibility={true}
                  iconClasses="!tw-bg-transparent"
                  onToggle={() => setShowPassword((prev) => !prev)}
                  tabIndex={4}
                  name="confirmPassword"
                  readOnly={passwordReadOnly}
                  autoComplete="new-password"
                />
                {showConfirmPasswordError && !matchingPassword && (
                  <div className="tw-mt-1 tw-text-sm tw-text-red-500">{t('signup.passwordNotMatching')}</div>
                )}
              </div>
              <div
                className={`tw-relative tw-flex tw-flex-col tw-gap-2.5 tw-rounded-md focus-within:tw-shadow-none focus:!tw-bg-white`}
              >
                <PhoneInput
                  phoneNumber={phoneNumber}
                  defaultCountryCode={browserCountryCode}
                  placeholder={registerPhone ? t('signup.enterPhoneNumber') : t('signup.enterPhoneNumberOptional')}
                  onChange={(value: string, country: Country) => {
                    setPhoneNumber(value);
                    setSelectedCountry(country.countryCode);
                  }}
                  containerClassName={`tw-w-full tw-h-[38px]    ${
                    triedToSubmit && registerPhone && !isPhoneValid ? '  tw-shadow-missing-input-data' : ''
                  }`}
                  inputClassName="tw-text-sm tw-w-full"
                  renderButtonTabIndex={5}
                  phoneTabIndex={6}
                  errorBorder={triedToSubmit && registerPhone && !isPhoneValid}
                  onBlur={() => {
                    if (!isPhoneValid) {
                      setShowInvalidPhoneError(true);
                    } else {
                      setShowInvalidPhoneError(false);
                    }
                  }}
                />
                {showInvalidPhoneError && (
                  <div className="tw-mt-1 tw-text-sm tw-text-red-500">{t('signup.notAValidNumber')}</div>
                )}
              </div>
              <div className="tw-flex tw-items-center tw-gap-3.5">
                <IndeterminateCheckbox
                  className="tw-bg-white"
                  {...{
                    checked: registerPhone,

                    onChange: () => setRegisterPhone((prev) => !prev),
                  }}
                  tabIndex={7}
                />
                <div className="tw-flex">
                  <span>{t('signup.registerPhone')}</span>
                </div>
              </div>
              <div className="tw-flex tw-gap-3.5">
                <IndeterminateCheckbox
                  className={`tw-bg-white ${triedToSubmit && !accpetedTAndC ? '!tw-border-sb-orange-400 tw-shadow-missing-input-data focus:!tw-border-sb-orange-400' : ''}`}
                  {...{
                    checked: accpetedTAndC,

                    onChange: () => setAcceptedTAndC((prev) => !prev),
                  }}
                  tabIndex={8}
                />
                <div className="tw-flex tw-items-center">
                  <span className="tw-inline-block">
                    <Trans
                      i18nKey="signup.readAndAcceptedThe"
                      components={{
                        customButton: (
                          <a
                            target="_blank"
                            href={TERMS_OF_USE}
                            className="!tw-m-0 tw-border-none tw-bg-transparent !tw-p-0 tw-text-inherit tw-text-sb-blue-300"
                          >
                            {t('signup.termsAndConditions')}
                          </a>
                        ),
                      }}
                    />

                    <span className="tw-text-sb-orange-500">*</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5">
              <Button
                size="large"
                className={`tw-w-full ${disableSubmit ? 'tw-cursor-not-allowed tw-bg-sb-blue-grey-300 !tw-text-sb-blue-grey-500 hover:tw-bg-sb-blue-grey-300 active:tw-bg-sb-blue-grey-300' : ''}`}
                type="submit"
                disabled={processing}
                tabIndex={9}
              >
                {t('signUp')}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <LoadingModal
        modalOpen={showLoadingModal}
        loadingMessage={loadingMessage}
        error={!!error}
        onClose={handleCloseLoadingModal}
      />
    </div>
  );
};
