import { getAuthenticatedUser } from '@modules/auth/utils';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useSentry = () => {
  const [sentryUserIsSet, setSentryUserIsSet] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (!sentryUserIsSet) {
      if (!location.pathname.includes('auth')) {
        (async () => {
          try {
            const user = await getAuthenticatedUser();
            Sentry.setUser({ email: user?.email });
            setSentryUserIsSet(true);
          } catch (error) {
            Sentry.setUser(null);
            setSentryUserIsSet(false);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
