import { getAuthenticatedUser } from '@modules/auth/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export type EmailVerifiedState = {
  isEmailVerified: boolean;
  email: string;
  name?: string;
  given_name?: string;
  family_name?: string;
};

const initialState: EmailVerifiedState = {
  isEmailVerified: true,
  email: '',
  name: '',
  given_name: '',
  family_name: '',
};

export const checkCognitoEmailVerified = createAsyncThunk('emailVerified/checkCognitoEmailVerified', async () => {
  const user = await getAuthenticatedUser(true);

  return {
    email: user?.email,
    emailVerified: user?.emailVerified,
    name: user?.name,
    given_name: user?.given_name,
    family_name: user?.family_name,
  };
});

const emailVerifiedSlice = createSlice({
  name: 'emailVerified',
  initialState,
  reducers: {
    updateEmail: (state, action) => {
      state.email = action.payload.email;
    },
    verifymEmail: (state) => {
      state.isEmailVerified = true;
    },
    unverifymEmail: (state) => {
      state.isEmailVerified = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCognitoEmailVerified.fulfilled, (state, action) => {
      state.isEmailVerified = action.payload.emailVerified;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.given_name = action.payload.given_name;
      state.family_name = action.payload.family_name;
    });
  },
});

export const { verifymEmail, unverifymEmail } = emailVerifiedSlice.actions;
export default emailVerifiedSlice;
