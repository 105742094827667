import { Loader } from '@components/Loader';
import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { useInterval } from 'src/lib/utils/hooks/useInterval';
import { useLazyGetBillingBalanceQuery } from '../billing-api-slice';

const PaymentSuccess = ({ isOpen, handleClose }) => {
  const customerId = useCustomerId();
  // , getBillingBalance
  const [counter, setCounter] = useState(0);
  const [intervalTime, setIntervalTime] = useState(null);

  const [getBillingBalance] = useLazyGetBillingBalanceQuery();
  useInterval(() => {
    if (isOpen && customerId) {
      getBillingBalance();
      setCounter(counter + 1);
    }
  }, intervalTime);

  useEffect(() => {
    if (isOpen) {
      // Start interval
      setIntervalTime(2000);
    } else {
      // Stop interval
      setCounter(0);
      setIntervalTime(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (counter === 2) {
      handleClose();
    }
  }, [handleClose, counter]);

  return (
    <Modal centered show={isOpen}>
      <ModalBody>
        <div className="d-flex justify-content-center flex-column">
          <div>
            Recharge successful. Your recharge is being processed and we’ll appear in the balance in a few moments
          </div>
          <Loader />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PaymentSuccess;
