import {
  useAddTagToSimsMutation,
  useGetTagsQuery,
  useRemoveTagToSimsMutation,
} from '@modules/dashboard/simCard/simcards-api-slice';
import { useCustomerId } from './useCustomerId';

export const useTags = () => {
  const customerId = useCustomerId();
  const getTagsResponse = useGetTagsQuery(undefined, { skip: !customerId });
  const tags = getTagsResponse?.data?.tags.map((tag) => tag.tag) || [];

  const [addTagToSims, addTagToSimsResponse] = useAddTagToSimsMutation();
  const [removeTagToSims, removeTagToSimsResponse] = useRemoveTagToSimsMutation();

  return { tags, getTagsResponse, addTagToSims, addTagToSimsResponse, removeTagToSims, removeTagToSimsResponse };
};
