import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { AnchorProps } from 'node_modules/@headlessui/react/dist/internal/floating';
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type BaseDropdownProps = {
  children: (open: boolean, close: () => void) => React.ReactElement;
  menuItems: (filterQuery: string) => ReactNode;
  //Use the values top, right, bottom, or left to center the dropdown along the appropriate edge,
  //or combine it with start or end to align the dropdown to a specific corner,
  //such as 'top start' or 'bottom end'.
  anchorPosition?: AnchorProps;
  title?: string;
  showFilter?: boolean;
  filterPlaceholder?: string;
  bottomButton?: ReactNode;
  keyFunction?: string;
  customMenuItemsClassName?: string;
  menuButtonClassName?: string;
};

const MENU_ITEMS_CLASS = `
  tw-border-sb-blue-grey-300 tw-flex tw-flex-col tw-items-start tw-justify-center 
  tw-gap-0 tw-border tw-border-solid tw-bg-white tw-py-0 tw-z-20  !tw-overflow-hidden
`;

const getMenuItemsClassName = (
  title: string | undefined,
  showFilter: boolean,
  bottomButton: ReactNode | undefined,
  customMenuItemsClassName,
) => `
  ${MENU_ITEMS_CLASS}
  ${title || showFilter ? 'tw-rounded-b-lg tw-border-t-0' : 'tw-rounded-lg'} 
  ${bottomButton ? 'tw-border-b-0 !tw-rounded-b-none tw-rounded-none' : ''}
  ${customMenuItemsClassName}
`;

export const BaseDropdown = React.memo(
  forwardRef(
    (
      {
        children,
        menuItems,
        anchorPosition = 'bottom start',
        showFilter = false,
        title,
        filterPlaceholder,
        bottomButton,
        keyFunction,
        customMenuItemsClassName = '',
        menuButtonClassName = '',
      }: BaseDropdownProps,
      ref,
    ) => {
      const { t } = useTranslation();
      const [filterQuery, setFilterQuery] = useState('');
      const filterInputRef = useRef<HTMLInputElement>(null);
      const menuItemsContainerRef = useRef<HTMLDivElement>(null);

      const menuItemsClassName = useMemo(
        () => getMenuItemsClassName(title, showFilter, bottomButton, customMenuItemsClassName),
        [title, showFilter, bottomButton],
      );

      return (
        <Menu>
          {({ open, close }) => {
            useImperativeHandle(ref, () => ({
              closeMenu: () => close(),
            }));

            useEffect(() => {
              if (!open) {
                setFilterQuery('');
              }
            }, [open]);

            useEffect(() => {
              setTimeout(() => {
                if (open && filterInputRef.current) {
                  filterInputRef.current.focus();
                }
              }, 0);
            }, [open]);
            return (
              <>
                <MenuButton
                  as="button"
                  className={`!tw-m-0 tw-flex tw-items-center tw-rounded tw-border-transparent tw-bg-transparent tw-p-0 tw-text-inherit ${menuButtonClassName}`}
                  type="button"
                >
                  {({ open: menuOpen }) => children(menuOpen, close)}
                </MenuButton>

                <div>
                  <MenuItems
                    anchor={anchorPosition}
                    className="tw-z-20 tw-overflow-hidden tw-shadow-dropdown-shadow [--anchor-gap:4px] focus:tw-outline-none"
                  >
                    {!!title && (
                      <div className="tw-flex tw-w-full tw-flex-grow tw-items-center tw-justify-between tw-rounded-t-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5 tw-pl-4">
                        <div>
                          <span className="tw-text-base tw-text-sb-blue-grey-600">{t(title)}</span>
                        </div>
                        {keyFunction && (
                          <div className="tw-flex tw-h-[25px] tw-w-[25px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300">
                            <span>{keyFunction}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {showFilter && (
                      <div className="tw-flex tw-w-full tw-flex-grow tw-items-center tw-rounded-t-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5 tw-pl-4">
                        <input
                          ref={filterInputRef}
                          type="text"
                          value={filterQuery}
                          onChange={(e) => setFilterQuery(e.target.value)}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                              e.preventDefault();
                              const container = menuItemsContainerRef.current;
                              if (container) {
                                const firstItem = container.querySelector('[role="menuitem"]') as HTMLElement;
                                firstItem?.focus();
                              }
                            }
                          }}
                          placeholder={t('dropdown.filterBy', { filter: filterPlaceholder })}
                          className="tw-flex-grow tw-border-none tw-p-0 tw-text-sb-blue-grey-600 placeholder:tw-text-sb-blue-grey-300 focus:tw-outline-none focus:tw-ring-0"
                        />
                        {keyFunction && (
                          <div className="tw-flex tw-h-[25px] tw-w-[25px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300">
                            <span>{keyFunction}</span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={menuItemsClassName} ref={menuItemsContainerRef}>
                      {menuItems(filterQuery)}
                    </div>
                    {!!bottomButton && bottomButton}
                  </MenuItems>
                </div>
              </>
            );
          }}
        </Menu>
      );
    },
  ),
);
