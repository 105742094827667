import { Button } from '@components/Atoms/Button/Button';
import { Modal } from '@components/Atoms/Modal/Modal';
import { ConfirmEmailUpdateModal } from '@components/ConfirmEmailUpdateModal';
import { HeaderNavigation } from '@components/Molecules/HeaderNavigation/HeaderNavigation';
import { InviteModal } from '@components/Molecules/InviteModal/InviteModal';
import { LoadingModal } from '@components/Molecules/LoadingModal/LoadingModal';
import { OrganizationModal } from '@components/Organisms/OrganizationModal/OrganizationModal';
import { Sidebar } from '@components/Organisms/Sidebar/Sidebar';
import Home from '@components/Pages/Home';
import { useSignout } from '@modules/auth/signout';
import { getAuthenticatedUser } from '@modules/auth/utils';
import * as Sentry from '@sentry/react';
import useIsProcessing from '@utils/hooks/useIsProcessing';
import { setItem } from '@utils/localStorage';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useEmailVerified } from 'src/hooks/useConfirmEmail';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { Invitation } from 'src/types/types';
import { SimcardsView } from '../../components/Organisms/SimcardsView/SimcardsView';
import { VIEW_PERMISSIONS } from '../../core/constants'; //PERMISSIONS,

import { setCustomerId } from '../../core/features/customerSlice';
import { useAppDispatch } from '../../core/rtk-hooks';
import { checkPermission } from '../../lib/utils/permissions';
import { Account } from './account';
import {
  useCreateOrganizationMutation,
  useGetCustomerQuery,
  useGetIpInfoQuery,
  useGetSelfQuery,
  useJoinOrganizationMutation,
} from './account/account-api-slice';
import { RegisterSims } from './activateSims';
import { Billing } from './billing';
import { useGetBillingBalanceQuery, useGetCountriesQuery } from './billing/billing-api-slice';
import { useGetSummaryQuery } from './home/status-api-slice';
import { Integrations } from './integrations';
import { ConfigureIPSec, CreateNetwork, Network, NetworkDetails } from './network';
import { Details } from './simCard';
import { useSimcards } from './simCard/hooks/useSimcards';

function RequirePermissions({ children, permissions }) {
  let isAllowed = false;
  permissions.forEach((permission) => {
    if (checkPermission(permission)) {
      isAllowed = true;
    }
  });
  return isAllowed ? children : <Navigate replace to="/dashboard/home" />;
}

const AUTHENTICATION_INTERVAL = 5 * 60 * 1000; // 5 minutes
const AUTHENTICATION_CACHE_DURATION = 4.5 * 60 * 1000; // 4.5 minutes

const Router = () => {
  const { t } = useTranslation();
  const [showOrganizationModal, setShowOrganizationModal] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const location = useLocation();
  const { pathname } = location;
  const accessTokenRef = useRef(null);
  const [accessToken, setAccessToken] = useState(null);
  const [signoutModalOpen, setSignoutModalOpen] = useState(false);
  const [lastAuthCheck, setLastAuthCheck] = useState(0);
  const [billingDetails, setBillingDetails] = useState({
    companyName: '',
    purchaseOrder: '',
    country: '',
    attentionTo: '',
    primaryAddress: '',
    secondaryAddress: '',
    zipCode: '',
    state: '',
    city: '',
    vat: '',
    email_invoice: '',
  });
  const [billingDetailsAreReady, setBillingDetailsAreReady] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>('');

  const [createOrganization] = useCreateOrganizationMutation();
  const [joinOrganization] = useJoinOrganizationMutation();

  const getIpInfo = useGetIpInfoQuery();
  const { data: ipInfo } = getIpInfo;

  const dispatch = useAppDispatch();
  const customerId = useCustomerId();

  const updateBillingDetails = useCallback((newDetails) => {
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
  }, []);

  // This is used to track the scrolling for the SimcardActionBar in SimcardsTable
  const scrollContainerRef = useRef(null);

  const checkAuthentication = useCallback(async () => {
    const now = Date.now();
    if (now - lastAuthCheck < AUTHENTICATION_CACHE_DURATION) {
      return;
    }

    try {
      const user = await getAuthenticatedUser(true);
      if (!user?.accessToken) {
        Sentry.captureMessage('Authentication check: missing access token', { level: 'error' });
        accessTokenRef.current = null;
        setAccessToken(null);
        setSignoutModalOpen(true);
        return;
      } else {
        accessTokenRef.current = user.accessToken;
        setAccessToken(user.accessToken);
        Sentry.setUser({
          id: user.username,
          email: user.email,
          username: user.username,
        });
        Sentry.setTag('customer_id', user.customer_id || 'missing');
      }

      if (!user?.customer_id) {
        Sentry.captureMessage('Missing customer_id from user in checkAuthentication flow', {
          level: 'warning',
          extra: { user },
        });
        setShowOrganizationModal(true);
        dispatch(setCustomerId(null));
      } else {
        dispatch(setCustomerId(user.customer_id));
        setShowOrganizationModal(false);
      }

      setLastAuthCheck(now);
    } catch (error) {
      console.error('Error during authentication check:', error);
      Sentry.captureException(error);
      accessTokenRef.current = null;
      setAccessToken(null);
      setSignoutModalOpen(true);
    }
  }, [lastAuthCheck, dispatch]);

  const debouncedCheckAuthentication = useMemo(() => debounce(() => checkAuthentication(), 300), [checkAuthentication]);

  useEffect(() => {
    debouncedCheckAuthentication();

    const intervalId = setInterval(debouncedCheckAuthentication, AUTHENTICATION_INTERVAL);

    return () => {
      clearInterval(intervalId);
      debouncedCheckAuthentication.cancel();
    };
  }, [debouncedCheckAuthentication, pathname]);

  const { forceSignout } = useSignout();

  async function handleForceSignout() {
    await forceSignout();
  }

  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 1073);
  const [username, setUsername] = useState(sessionStorage.getItem('username') || '');
  const [usernameIsLoading, setUsernameIsLoading] = useState(true);

  // GetBillingBalance
  const {
    data: balance,
    isLoading: balanceIsLoading,
    isFetching: balanceIsFetching,
  } = useGetBillingBalanceQuery(null, {
    skip: !customerId,
    refetchOnMountOrArgChange: true,
  });

  // GetCountries
  const {
    data: countriesData,
    isLoading: countriesIsLoading,
    isFetching: countriesIsFetching,
  } = useGetCountriesQuery(undefined, { skip: !customerId });

  // GetCustomer
  const customerResponse = useGetCustomerQuery(undefined, {
    skip: !customerId,
    refetchOnMountOrArgChange: true,
  });
  const { data: customerResponseDetails, isFetching: customerResponseIsFetching } = customerResponse;

  const topUpIsProcessing = useIsProcessing([
    countriesIsLoading,
    countriesIsFetching,
    balanceIsLoading,
    balanceIsFetching,
    !billingDetailsAreReady,
  ]);

  // GetSimcards
  const getSimsResponse = useSimcards();
  const { simcards, refetch: refetchSimcards } = getSimsResponse;
  const simcardsData = useMemo(() => simcards, [simcards]);

  const [polling, setPolling] = useState(false);

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | null = null;

    if (polling) {
      pollingInterval = setInterval(async () => {
        const user = await getAuthenticatedUser(true);

        setItem('role', user.session.accessToken?.payload['cognito:groups']?.[0] || 'user');
        setUserRole(user.session.accessToken?.payload['cognito:groups']?.[0] || 'user');
        if (user.customer_id) {
          dispatch(setCustomerId(user.customer_id));
          refetchSimcards();
          setUserRole(user.session.accessToken?.payload['cognito:groups']?.[0] || 'user');
          setPolling(false);
        }
      }, 2000);
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [polling, dispatch]);

  useEffect(() => {
    if (customerResponseDetails?.customerDetails) {
      const { company_name, address, invoice, tax, email } = customerResponseDetails.customerDetails;
      const { zip, state, city, country, street1, street2 } = address || {};
      const { invoice_ref, invoice_attendee } = invoice || {};
      const { tax_number } = tax || {};
      const { email_invoice } = email || {};

      setBillingDetails({
        companyName: company_name || '',
        purchaseOrder: invoice_ref || '',
        country: country || '',
        attentionTo: invoice_attendee || '',
        primaryAddress: street1 || '',
        secondaryAddress: street2 || '',
        zipCode: zip || '',
        state: state || '',
        city: city || '',
        vat: tax_number || '',
        email_invoice: email_invoice || '',
      });
      setBillingDetailsAreReady(true);
    }
  }, [customerResponseDetails]);

  useEffect(() => {
    const storedUsername = sessionStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
    setUsernameIsLoading(false);
  }, []);

  const { data: userData } = useGetSelfQuery();

  useEffect(() => {
    if (userData?.name) {
      setUsername(userData.name);
      sessionStorage.setItem('username', userData.name);
    }
  }, [userData?.name]);

  const getSummaryResponse = useGetSummaryQuery(undefined, { skip: !customerId });
  const statusSummary = getSummaryResponse?.data;

  const { isEmailVerified } = useEmailVerified(!accessToken || signoutModalOpen);

  const handleCreateOrganization = async (companyName: string, currency: string) => {
    setProcessing(true);
    setShowLoadingModal(true);
    setLoadingMessage(t('signup.creatingOrganization', { companyName: companyName }));

    try {
      await createOrganization({ company_name: companyName, currency });

      setPolling(true);

      setShowOrganizationModal(false);
      setShowLoadingModal(false);
      setShowInviteModal(true);
    } catch (error) {
      setError(true);
      setLoadingMessage(t('errorMessage.somethingWentWrongGetInTouch'));
      console.error('Error joining organization', error);
    } finally {
      setProcessing(false);
    }
  };

  const handleJoinOrganization = async (organization: Invitation) => {
    setProcessing(true);
    setShowLoadingModal(true);
    setLoadingMessage(t('signup.joiningOrganization', { organization: organization.company_name }));
    try {
      await joinOrganization({ customer_id: organization.customer_id });

      setPolling(true);

      setShowOrganizationModal(false);
    } catch (error) {
      setError(true);
      setLoadingMessage(t('errorMessage.somethingWentWrongGetInTouch'));
      console.error('Error joining organization', error);
    } finally {
      setProcessing(false);
    }
  };

  const handleCloseErrorModal = () => {
    setShowLoadingModal(false);
    setTimeout(() => {
      setError(false);
    }, 300);
  };

  return (
    <div className="tw-h-screen tw-overflow-hidden tw-bg-sb-blue-grey-100">
      <Sidebar
        user={username}
        status={statusSummary?.status}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        url={statusSummary?.page?.url}
        usernameIsLoading={usernameIsLoading}
        userRole={userRole}
      />
      <div
        ref={scrollContainerRef}
        className={`tw-h-screen tw-overflow-y-auto tw-transition-all tw-duration-100 tw-ease-in-out ${
          sidebarOpen ? 'tw-pl-60' : 'tw-pl-[63px]'
        }`}
      >
        <div className="tw-min-h-full">
          <Routes>
            <Route
              path="/sim-cards/:iccid"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.SIMCARDS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Details sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/sim-cards"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.SIMCARDS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <SimcardsView
                    sidebarOpen={sidebarOpen}
                    simcardsData={simcardsData}
                    simsResponseIsFulfilled={getSimsResponse.isFulfilled}
                    scrollContainerRef={scrollContainerRef}
                  />
                </RequirePermissions>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Account sidebarOpen={sidebarOpen} />
                </>
              }
            />
            <Route
              path="/billing"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.BILLING]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Billing
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    customerResponseDetails={customerResponseDetails}
                    customerResponseIsFetching={customerResponseIsFetching}
                    billingDetailsAreReady={billingDetailsAreReady}
                    updateBillingDetails={updateBillingDetails}
                    billingDetails={billingDetails}
                  />
                </RequirePermissions>
              }
            />
            <Route path="/activate-sims" element={<RegisterSims />} />
            <Route
              path="/home"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.HOME]}>
                  <Home
                    user={username.split(' ')[0]}
                    balance={balance}
                    billingBalanceResponseIsLoading={balanceIsLoading}
                    customerId={customerId}
                  />
                </RequirePermissions>
              }
            />
            <Route
              path="/integrations"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.INTEGRATIONS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Integrations sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network/create"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <CreateNetwork sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Network sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network/:id/"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <NetworkDetails sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network/:id/ipsec-configure"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <ConfigureIPSec sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route path="*" element={<Navigate replace to="/dashboard/home" />} />
          </Routes>
        </div>
      </div>
      {signoutModalOpen && (
        <Modal
          showClose={true}
          title={t('route.signInAgain')}
          modalOpen={signoutModalOpen}
          closeModal={handleForceSignout}
        >
          <div className="tw-flex tw-flex-col">
            <p>{t('route.signInAgain.body')}</p>
            <Button variant="fourth" onClick={handleForceSignout}>
              {t('button.reload')}
            </Button>
          </div>
        </Modal>
      )}
      {showOrganizationModal && userData && (
        <OrganizationModal
          open={showOrganizationModal}
          closeModal={() => setShowOrganizationModal(false)}
          invitations={userData?.invitations}
          currency={ipInfo?.currency}
          handleCreateOrganization={handleCreateOrganization}
          processing={processing}
          handleJoinOrganization={handleJoinOrganization}
        />
      )}
      {showInviteModal && (
        <InviteModal open={showInviteModal} closeModal={() => setShowInviteModal(false)} showSkip={true} />
      )}
      {accessToken && !isEmailVerified && !signoutModalOpen && <ConfirmEmailUpdateModal />}
      <LoadingModal
        modalOpen={showLoadingModal}
        loadingMessage={loadingMessage}
        error={error}
        onClose={handleCloseErrorModal}
      />
    </div>
  );
};

export default Router;
