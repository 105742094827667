import { getAuthenticatedUser } from '@modules/auth/utils';
import * as sentry from '@sentry/react';

// import { Auth } from "aws-amplify";

// interface CustomerIoIdentify {
//   email: string;
// }

export const customerIoIdentify = async () => {
  if (typeof window !== 'undefined') {
    const _cio = (window as any)._cio || [];
    if (_cio.identify) {
      try {
        const user = await getAuthenticatedUser();
        if ('_pluginsLoaded' in _cio) {
          _cio.identify({ id: user?.email });
        } else {
          const maxRetries = 10;
          let retries = 0;
          const intervalId = setInterval(() => {
            if ('_pluginsLoaded' in _cio) {
              _cio.identify({ id: user?.email });
              clearInterval(intervalId);
              retries++;
              if (retries > maxRetries) {
                clearInterval(intervalId);
                sentry.captureException(new Error('CustomerIO identify failed'));
              }
            }
          }, 500);
        }
      } catch (err) {
        sentry.captureException(err);
      }
    }
  }
};

export const customerIoTrack = (eventData: { name: string; data: any }) => {
  try {
    const _cio = (window as any)._cio || [];
    _cio.track(eventData.name, eventData.data);
  } catch (err) {
    sentry.captureException(err);
  }
};

export const customerIoResetIdentify = () => {
  const intervalId = setInterval(() => {
    try {
      if (window && '_cio' in window) {
        const { _cio } = window as unknown as any;
        _cio.reset();
        clearInterval(intervalId);
      }
    } catch (err) {
      sentry.captureException(err);
    }
  }, 500);
};

export const customerIoTrackPage = (pageName: string): void => {
  const _cio = (window as any)._cio || [];
  if ('page' in _cio) {
    _cio.page(pageName);
  }
};
