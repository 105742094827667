import { useEffect } from 'react';
import { useCustomerId } from 'src/hooks/useCustomerId';
import DeprecatedSimpleTable from '../../../../components/Organisms/SimpleTable/DeprecatedSimpleTable';
import { useLazyGetCostReportQuery } from '../billing-api-slice';

interface ICostDetails {
  month: string;
  iccid: string;
  handleGoBack: () => void;
}

const CostDetails = ({ month, iccid, handleGoBack }: ICostDetails) => {
  const customerId = useCustomerId();
  const [getCostReport, costReportResponse] = useLazyGetCostReportQuery();
  const costReport = costReportResponse?.data?.lines ?? [];

  const columns = [
    { Header: 'EVENT DATE', accessor: 'timestamp' },
    { Header: 'TYPE', accessor: 'type' },
    { Header: 'VOLUME', accessor: 'volume' },
    { Header: 'UNIT', accessor: 'unit' },
    { Header: 'LOCATION', accessor: 'country' },
    { Header: 'MOBILE NETWORK OPERATOR', accessor: 'carrier' },
    { Header: 'PLAN & COVERAGE', accessor: 'plan' },
    { Header: 'COST', accessor: 'cost' },
  ];

  useEffect(() => {
    if (customerId) {
      getCostReport({ iccid, month });
    }
  }, [getCostReport, iccid, month, customerId]);

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <i onClick={handleGoBack} className="fe fe-arrow-left fs-2 fw-400 cursor-pointer" />
        <div onClick={handleGoBack} className="mt-1 ms-1 cursor-pointer text-capitalize">
          Back to SIM Report
        </div>
      </div>
      <div>
        <DeprecatedSimpleTable
          title="Billed items"
          titleFontWeight="500"
          columns={columns}
          data={costReport}
          processing={costReportResponse.isFetching}
        />
      </div>
    </div>
  );
};

export default CostDetails;
