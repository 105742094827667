import { MAPBOX_KEY } from '@core/constants';
import { getCountryCoordsFromName } from '@utils/getCountryCoordsFromName/getCountryCoordsFromName';
import { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import Map, { FullscreenControl, MapRef, Marker } from 'react-map-gl';

export const MapBox = ({ coords, isEmpty = false, countryName }) => {
  let longitude;
  let latitude;
  if (!isEmpty) {
    longitude = coords[0] === 'Unknown' ? 0 : coords[0];
    latitude = coords[1] === 'Unknown' ? 0 : coords[1];
  }
  if (isEmpty) {
    const placeholderCoords = getCountryCoordsFromName(countryName);
    longitude = placeholderCoords.longitude;
    latitude = placeholderCoords.latitude;
  }

  const mapRef = useRef<MapRef>();

  useEffect(() => {
    if (mapRef.current && !isEmpty) {
      mapRef.current.flyTo({ center: [longitude, latitude], zoom: 13 });
    }
  });

  return (
    <Card className="mb-0">
      <Card.Body className="p-3">
        <Map
          ref={mapRef}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          projection="globe"
          mapboxAccessToken={MAPBOX_KEY}
          initialViewState={{
            longitude: longitude,
            latitude: latitude,
            zoom: isEmpty ? 5 : 12,
          }}
          // terrain={{ source: "mapbox-dem", exaggeration: 1.5 }}
          style={{ height: '250px', width: '100%' }}
          mapStyle="mapbox://styles/mapbox/streets-v12" // satellite-
        >
          {/* <Source  // 3d terrain
            id="mapbox-dem"
            type="raster-dem"
            url="mapbox://mapbox.mapbox-terrain-dem-v1"
            tileSize={512}
            maxzoom={14}
          /> */}
          <FullscreenControl />
          {/* <GeolocateControl /> */}
          {/* <NavigationControl /> */}
          {!isEmpty && <Marker color="#ff5745" scale={0.8} longitude={longitude} latitude={latitude} />}
        </Map>
      </Card.Body>
    </Card>
  );
};

export default MapBox;
