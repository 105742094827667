import { useEffect, useState } from 'react';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { useGetBillingBalanceQuery, useUpdateBillingBalanceMutation } from '../billing-api-slice';

export const useAutoTopUp = (): {
  updateBillingBalance: (data: {
    auto_topup: boolean;
    topup_threshold_in_cents: number;
    topup_amount_in_cents: number;
  }) => Promise<any>;
  updateBillingBalanceResponse: any;
  autoTopUp: boolean;
  setAutoTopUp: (value: boolean) => void;
  topUpAmount: number;
  topUpTreshold: number;
  isTopUpFetchSuccess: boolean;
  isTopUpFetchFetching: boolean;
} => {
  const customerId = useCustomerId();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const balanceResponse = useGetBillingBalanceQuery(undefined, { skip: !customerId });
  const { auto_topup, topup_amount_in_cents, topup_threshold_in_cents } = balanceResponse.data ?? {};
  const [autoTopUp, setAutoTopUp] = useState<boolean>(false);

  useEffect(() => {
    setAutoTopUp(() => auto_topup);
  }, [auto_topup, balanceResponse.isSuccess]);

  const [updateBillingBalance, updateBillingBalanceResponse] = useUpdateBillingBalanceMutation();

  return {
    isTopUpFetchSuccess: balanceResponse.isSuccess,
    isTopUpFetchFetching: balanceResponse.isFetching,
    autoTopUp,
    setAutoTopUp,
    topUpAmount: topup_amount_in_cents as unknown as number,
    topUpTreshold: topup_threshold_in_cents as unknown as number,
    updateBillingBalance,
    updateBillingBalanceResponse,
  };
};
