import { LoadingButtonContent } from '@components/LoadingButtonContent';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { z } from 'zod';
import { useGetBillingBalanceQuery, useUpdateBillingBalanceMutation } from '../../billing-api-slice';

// import { useAutoTopUp } from "../../hooks/useAutoTopUp";

type ValidationError = {
  code: 'too_small' | 'too_big' | 'invalid_type' | 'custom';
  message: string;
  path: 'topup' | 'treshold'[];
  minimum?: number;
  type?: string;
  inclusive?: boolean;
  exact?: boolean;
};

const initialValidationErrors = {
  threshold: '',
  topup: '',
  custom: '',
};

const AutoTopupModal = forwardRef(({ currencySymbol }: { currencySymbol: string }, ref) => {
  const customerId = useCustomerId();
  const balanceResponse = useGetBillingBalanceQuery(undefined, { skip: !customerId });
  const { auto_topup, topup_amount_in_cents = 0, topup_threshold_in_cents = 0 } = balanceResponse.data ?? {};
  const [updateBillingBalance, updateBillingBalanceResponse] = useUpdateBillingBalanceMutation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [threshold, setThreshold] = useState<number>(0);
  const [topup, setTopup] = useState<number>(0);
  const [errors, setErrors] = useState<Record<string, unknown>>(initialValidationErrors);

  useEffect(() => {
    if (balanceResponse.isSuccess) {
      setThreshold(auto_topup ? Number(topup_threshold_in_cents) / 100 : 0);
      setTopup(auto_topup ? Number(topup_amount_in_cents) / 100 : 0);
    }
  }, [balanceResponse.status]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
  }));

  const AutoTopupSchema = z
    .object({
      threshold: z
        .number()
        .min(10, `The topup threshold should be higher than ${currencySymbol}10`)
        .max(1980, `The topup threshold should be lower than ${currencySymbol}1980`),
      topup: z
        .number()
        .min(30, `The topup target should be higher than ${currencySymbol}30`)
        .max(2000, `The topup target should be lower than ${currencySymbol}2000`),
    })
    .refine((data) => data.threshold + 20 <= data.topup, {
      message: `Topup amount should be at least ${currencySymbol}20 greater than the threshold amount.`,
    });

  const validateForm = () => {
    try {
      AutoTopupSchema.parse({ threshold, topup });
      setErrors(initialValidationErrors);
      return true;
    } catch (error) {
      const errorArray: ValidationError[] = JSON.parse(error);
      errorArray.forEach((error) => {
        if (error.path[0] === 'threshold') {
          setErrors((prev) => ({ ...prev, threshold: error.message }));
        } else setErrors((prev) => ({ ...prev, threshold: '' }));
        if (error.path[0] === 'topup') {
          setErrors((prev) => ({ ...prev, topup: error.message }));
        } else setErrors((prev) => ({ ...prev, topup: '' }));
        if (error.code === 'custom') {
          setErrors((prev) => ({ ...prev, custom: error.message }));
        } else setErrors((prev) => ({ ...prev, custom: '' }));
      });
      return false;
    }
  };

  useEffect(() => {
    if (threshold > 0 && topup > 0) validateForm();
  }, [threshold, topup]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      await updateBillingBalance({
        auto_topup: true,
        topup_threshold_in_cents: threshold * 100,
        topup_amount_in_cents: topup * 100,
      });
      setIsOpen(false);
    }
  };
  const nonNumeric = /[^0-9]/;
  return (
    <Modal centered show={isOpen} backdrop="static" onHide={() => setIsOpen(false)}>
      <Modal.Header className="d-flex align-items-center justify-content-between">
        <p className="fw-600 d-flex align-items-center justify-content-center fs-2 fw-600 mt-3">Auto Top-up</p>
        <span
          onClick={() => {
            setIsOpen(false);
            setThreshold(0);
            setTopup(0);
          }}
          className="fe fe-x cursor-pointer fs-1 m-0 p-0"
        />
      </Modal.Header>
      <Modal.Body className="">
        <form onSubmit={handleSubmit} className="vstack gap-3">
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="h2">Threshold</p>
              <label htmlFor="threshold" className="text-muted">
                When amount is lower than (min {currencySymbol}10)
              </label>
              <input
                type="text"
                className="form-control"
                name="threshold"
                value={threshold}
                onChange={(e) => {
                  if (!nonNumeric.test(e.target.value)) {
                    setThreshold(Number(e.target.value));
                  }
                }}
                onFocus={(event) => event.target.select()}
              />
              {errors.threshold && <p className="small text-danger">{errors.threshold as string}</p>}
            </div>
            <div className="col-12 col-md-6">
              <p className="h2">Target</p>
              <label htmlFor="topup" className="text-muted">
                Recharge balance to (min {currencySymbol}30)
              </label>
              <input
                className="form-control"
                type="text"
                name="topup"
                value={topup}
                onChange={(e) => {
                  if (!nonNumeric.test(e.target.value)) {
                    setTopup(Number(e.target.value));
                  }
                }}
                onFocus={(event) => event.target.select()}
                onBlur={() => setTopup((prev) => Number(prev))}
              />
              {errors.topup && <p className="small text-danger">{errors.topup as string}</p>}
            </div>
          </div>
          {errors.custom && (
            <div className="">
              <p className="small text-danger">{errors.custom as string}</p>
            </div>
          )}
          <div className="row">
            <div className="col-12 col-md-6 d-flex">
              <button
                type="button"
                className="btn btn-outline-danger flex-fill d-flex justify-content-center"
                disabled={!auto_topup}
                onClick={async () => {
                  await updateBillingBalance({
                    auto_topup: false,
                    // topup_threshold_in_cents: 0,
                    // topup_amount_in_cents: 0,
                  });
                  setIsOpen(false);
                  setThreshold(0);
                  setTopup(0);
                }}
              >
                <LoadingButtonContent
                  isLoading={
                    updateBillingBalanceResponse.isLoading && !updateBillingBalanceResponse.originalArgs.auto_topup
                  }
                  buttonText="Disable"
                  variant="light"
                />
              </button>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <button type="submit" className="btn btn-primary flex-fill d-flex justify-content-center">
                <LoadingButtonContent
                  isLoading={
                    updateBillingBalanceResponse.isLoading && updateBillingBalanceResponse.originalArgs.auto_topup
                  }
                  buttonText={`${auto_topup ? 'Update' : 'Submit'}`}
                  variant="light"
                />
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default AutoTopupModal;
