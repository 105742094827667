import { BASE_URL } from '@core/constants';
import { getAuthHeaders } from '@core/rtk-api';
import { AppDispatch, RootState } from '@core/rtk-store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Sim } from 'src/types/types';

export type CostsState = {
  costs: Sim[];
  month: string | null;
  isLoading: boolean;
  isFetching: boolean;
  isFulfilled: boolean;
  error: string | null;
  status: 'uninitialized' | 'loading' | 'fetching' | 'fulfilled' | 'error';
};

const initialState: CostsState = {
  costs: [],
  month: null,
  isLoading: false,
  isFetching: false,
  isFulfilled: false,
  error: null,
  status: 'uninitialized',
};

export const costsSlice = createSlice({
  name: 'costsState',
  initialState,
  reducers: {
    reset: () => initialState,
    setMonth: (state, action: PayloadAction<string>) => {
      state.month = action.payload;
    },
    addCosts: (state, action: PayloadAction<Sim[]>) => {
      state.costs.push(...action.payload);
    },
    startFetching: (state) => {
      state.isFulfilled = false;
      state.isFetching = true;
      state.error = null;
      state.status = 'fetching';
    },
    stopFetching: (state) => {
      state.isFetching = false;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = 'error';
    },
    startLoading: (state) => {
      state.isFulfilled = false;
      state.isLoading = true;
      state.error = null;
      state.status = 'loading';
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    setIsFulfilled: (state) => {
      state.isFulfilled = true;
      state.status = 'fulfilled';
    },
  },
});

export const fetchCostsThunk = (month: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { costsState } = getState();

  if (costsState.month !== month) {
    dispatch(costsSlice.actions.reset());
    dispatch(costsSlice.actions.setMonth(month));
  }

  let cursor: string | null = null;
  dispatch(costsSlice.actions.startLoading());

  do {
    try {
      const response = await fetch(`${BASE_URL}/costs/simcards?month=${month}${cursor ? `&cursor=${cursor}` : ''}`, {
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      cursor = data.cursor ?? null;
      const costs = data.sims || [];
      dispatch(costsSlice.actions.addCosts(costs));
    } catch (error: any) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to fetch costs';
      dispatch(costsSlice.actions.setError(errorMessage));
      return;
    }
  } while (cursor);

  dispatch(costsSlice.actions.stopLoading());
  dispatch(costsSlice.actions.setIsFulfilled());
};

export default costsSlice.reducer;
