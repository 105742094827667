import { PageNavBar } from '@components/Molecules/PageNavBar/PageNavBar';
import { VIEW_PERMISSIONS } from '@core/constants';
import useIsProcessing from '@utils/hooks/useIsProcessing';
import { getItem } from '@utils/localStorage';
import { useEffect } from 'react'; /* CHANGED: Added useEffect import */
import { useSearchParams } from 'react-router-dom';
import { useEmailVerified } from 'src/hooks/useConfirmEmail';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { OverviewHeader } from '../../components';
import { useGetCustomerQuery, useGetSelfQuery, useGetSubscriptionsQuery, useGetUsersQuery } from '../account-api-slice';
import { Notifications, Organization, Profile, Subscriptions } from '../index';

const tabs = [
  {
    key: 'organization',
    label: 'Organization',
    permission: VIEW_PERMISSIONS.ORGANIZATION,
  },
  {
    key: 'subscriptions',
    label: 'Subscriptions',
  },
  {
    key: 'profile',
    label: 'Profile',
  },
  {
    key: 'notifications',
    label: 'Notifications',
  },
];

type AccountProps = {
  sidebarOpen: boolean;
};

export const Account = ({ sidebarOpen }: AccountProps) => {
  const customerId = useCustomerId();
  const role = getItem('role');
  const defaultTab = role === 'user' || role === 'finance' ? 'profile' : tabs[0].key;
  const [searchParams, setSearchParams] = useSearchParams({ tab: defaultTab });

  const getCustomerResponse = useGetCustomerQuery(undefined, { skip: !customerId });
  const { refetch: refetchCustomer } = getCustomerResponse;

  const getSubscriptionsResponse = useGetSubscriptionsQuery(undefined, { skip: !customerId });

  const {
    givenName,
    familyName,
    name,
    email,
    isFetching: isFetchingEmail,
    updateEmailAndSendCode,
  } = useEmailVerified();

  const { data: userResponseData } = useGetUsersQuery(undefined, { skip: !customerId });

  const { data: userData } = useGetSelfQuery();

  const handleSelect = (tab) => {
    setSearchParams((prev) => {
      if (role === 'user' && (tab === 'organization' || tab === 'subscriptions')) {
        prev.set('tab', 'profile');
      } else if (role === 'finance' && tab === 'organization') {
        prev.set('tab', 'profile');
      } else {
        prev.set('tab', tab);
      }

      if (tab !== 'subscriptions') {
        prev.delete('subscription');
      }

      return prev;
    });
  };

  const tabParamFromURL = searchParams.get('tab');
  const tabParam = tabParamFromURL ? tabParamFromURL : defaultTab;

  useEffect(() => {
    if (role === 'user' && (tabParam === 'organization' || tabParam === 'subscriptions')) {
      setSearchParams({ tab: 'profile' });
    } else if (role === 'finance' && tabParam === 'organization') {
      setSearchParams({ tab: 'profile' });
    }
  }, [role, tabParam, setSearchParams]);

  let availableTabs = tabs;
  if (role === 'user') {
    availableTabs = tabs.filter((tab) => tab.key === 'profile' || tab.key === 'notifications');
  } else if (role === 'finance') {
    availableTabs = tabs.filter(
      (tab) => tab.key === 'profile' || tab.key === 'notifications' || tab.key === 'subscriptions',
    );
  }

  const isProcessing = useIsProcessing([getCustomerResponse.isLoading, getCustomerResponse.isFetching]);

  return (
    <div className="d-flex justify-content-center h-100">
      <div className={`mx-auto tw-flex tw-w-full tw-items-center tw-justify-center tw-px-6`}>
        <div className="tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col">
          <OverviewHeader title="Account" sidebarOpen={sidebarOpen} />

          <PageNavBar tabs={availableTabs} onSelect={handleSelect} selected={tabParam} />

          <div className="py-4">
            {tabParam === tabs[0].key && (
              <Organization
                setTab={handleSelect}
                customer={getCustomerResponse?.data}
                customerIsLoading={isProcessing}
                refetchCustomer={refetchCustomer}
                userResponseData={userResponseData}
                preferredTimezone={userData?.preferred_timezone}
              />
            )}
            {tabParam === tabs[1].key && (
              <Subscriptions
                customer={getCustomerResponse?.data}
                subscriptionsResponseIsLoading={getSubscriptionsResponse.isLoading}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
              />
            )}
            {tabParam === tabs[2].key && (
              <Profile
                givenName={givenName}
                familyName={familyName}
                name={name}
                email={email}
                isFetchingEmail={isFetchingEmail}
                updateEmailAndSendCode={updateEmailAndSendCode}
              />
            )}
            {tabParam === tabs[3].key && <Notifications />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
