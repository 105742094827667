import { SimcardsTable } from '@components/Organisms/SimcardsTable/SimcardsTable';
import { useAppSelector } from '@core/rtk-hooks';
import { useGetAllNetworksQuery } from '@modules/dashboard/network/network-api-slice';
import {
  FilterFnOption,
  filterFns,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { getItem } from '@utils/localStorage';
import { getColumnFilters, updateColumnsWithCustomDataSorting } from '@utils/simCardUtils/simCardUtils';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCustomerId } from 'src/hooks/useCustomerId';
import OverviewHeader from '../../../modules/dashboard/components/OverviewHeader';
import { columns as getColumns } from '../../../modules/dashboard/simCard/components/ListView/columns';
import {
  customCoverageFilter,
  customNetworkFilter,
  customTagsFilter,
} from '../../../modules/dashboard/simCard/components/ListView/filters';
import NoSimsModal from '../../../modules/dashboard/simCard/components/NoSimsModal';
import { useGetDashboardParametersQuery, useGetTagsQuery } from '../../../modules/dashboard/simCard/simcards-api-slice';
import { SimcardsListViewItem } from '../../../modules/dashboard/simCard/simcards-slice';

type SimcardsViewProps = {
  sidebarOpen: boolean;
  simcardsData: SimcardsListViewItem[];
  simsResponseIsFulfilled: boolean;
  scrollContainerRef: MutableRefObject<any>;
};
const SimcardsViewComponent = ({
  sidebarOpen,
  simcardsData,
  simsResponseIsFulfilled,
  scrollContainerRef,
}: SimcardsViewProps) => {
  const customerId = useCustomerId();
  const { t } = useTranslation();

  // Table Filters all handled by the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const sim_state = searchParams.get('sim_state') || null;
  const coverage = searchParams.get('coverage') ? searchParams.get('coverage').split(',') : [];
  const network_id = searchParams.get('network_ids') || null;
  const selectedTags = searchParams.get('tags') ? searchParams.get('tags').split(',') : [];

  const [globalFilter, setGlobalFilter] = useState<string>(searchValue);
  const [inputValue, setInputValue] = useState<string>(searchValue);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    setGlobalFilter(searchValue);
  }, [searchValue]);

  const handleFilter = useCallback(
    (value: string) => {
      setInputValue(value);
      setGlobalFilter(value);

      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        if (value) {
          params.set('search', value);
        } else {
          params.delete('search');
        }
        return params;
      });
    },
    [setSearchParams],
  );

  // Tags
  const tagsResponse = useGetTagsQuery(undefined, { skip: !customerId });
  const tags = tagsResponse?.data?.tags || [];

  // Networks
  const getAllNetworkResponse = useGetAllNetworksQuery(undefined, { skip: !customerId });
  const networksData = useMemo(() => getAllNetworkResponse.data || [], [getAllNetworkResponse.data]);
  const { isLoading: networksDataIsLoading } = getAllNetworkResponse;

  const noSimsModalRef = useRef(null);

  useEffect(() => {
    if (simsResponseIsFulfilled) {
      if (simcardsData.length === 0 && getItem('simModalOpen') !== 'opened' && noSimsModalRef?.current) {
        noSimsModalRef.current.open();
      }
    }
  }, [simsResponseIsFulfilled, simcardsData.length]);

  const getDashboardParamsResponse = useGetDashboardParametersQuery(undefined, { skip: !customerId });
  const coverageOptions = getDashboardParamsResponse?.data?.filters?.coverage || [];

  const columns = useMemo(() => getColumns({ t, tags }), [t, tags]);
  const updatedColumns = useMemo(() => updateColumnsWithCustomDataSorting(columns), [columns]);

  const sorting: SortingState = useMemo(() => {
    const currentSort = searchParams.get('sort');
    const currentDesc = searchParams.get('desc') === 'true';
    return currentSort ? [{ id: currentSort, desc: currentDesc }] : [];
  }, [searchParams]);

  const handleSortingChange = useCallback(
    (newSorting: (old: SortingState) => SortingState) => {
      let resolvedSorting: SortingState;

      const currentSort = searchParams.get('sort');
      const currentDesc = searchParams.get('desc') === 'true';
      const oldSorting = currentSort ? [{ id: currentSort, desc: currentDesc }] : [];

      resolvedSorting = newSorting(oldSorting);

      if (resolvedSorting.length > 0) {
        const { id, desc } = resolvedSorting[0];
        setSearchParams((prev) => {
          const params = new URLSearchParams(prev);
          params.set('sort', id);
          params.set('desc', desc.toString());
          return params;
        });
      } else {
        setSearchParams((prev) => {
          const params = new URLSearchParams(prev);
          params.delete('sort');
          params.delete('desc');
          return params;
        });
      }
    },
    [setSearchParams, searchParams],
  );

  const columnFilters = useMemo(() => {
    return getColumnFilters(coverage, network_id, selectedTags, sim_state);
  }, [coverage, network_id, selectedTags, sim_state]);

  const tableOptions = useMemo(
    () => ({
      data: simcardsData,
      columns: updatedColumns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      enableRowSelection: true,
      onSortingChange: handleSortingChange,
      filterFns: {
        ...filterFns,
        tagsFilter: customTagsFilter,
        coverageFilter: customCoverageFilter,
        network_idFilter: customNetworkFilter,
      },
      state: {
        globalFilter,
        sorting,
        columnFilters,
        rowSelection,
        pagination,
        columnVisibility: {
          network_id: false,
          search_iccid: false,
          search_sim_state: false,
          actions: false,
        },
      },
      onRowSelectionChange: setRowSelection,
      onPaginationChange: setPagination,
      globalFilterFn: 'includesString' as FilterFnOption<SimcardsListViewItem>,
      autoResetPageIndex: false,
      getRowId: (row) => row.iccid,
    }),
    [simcardsData, updatedColumns, globalFilter, sorting, columnFilters, rowSelection, pagination, handleSortingChange],
  );

  const table = useReactTable(tableOptions);

  const clearFilters = () => {
    setInputValue('');
    setGlobalFilter('');
    setSearchParams({});
  };

  const simcardsState = useAppSelector((state) => state.simcardsState);

  return (
    <div className="tw-flex tw-w-full tw-justify-center tw-px-6">
      <div className={`tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col`}>
        <OverviewHeader title={t('header.simCards')} sidebarOpen={sidebarOpen} headerClasses="!tw-mb-0" />
        <SimcardsTable
          tags={tags}
          coverage={coverageOptions}
          table={table}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          selectedCoverage={coverage}
          selectedTags={selectedTags}
          selectedState={sim_state}
          selectedNetwork={network_id}
          inputValue={inputValue}
          handleFilter={handleFilter}
          simCardState={simcardsState}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          clearFilters={clearFilters}
          rowSelection={rowSelection}
          paginationState={pagination}
          setRowSelection={setRowSelection}
          simcardsData={simcardsData}
          simsResponseIsFulfilled={simsResponseIsFulfilled}
          sidebarOpen={sidebarOpen}
          tagsResponseIsLoading={tagsResponse?.isLoading}
          coverageResponseIsLoading={getDashboardParamsResponse?.isLoading}
          networksData={networksData}
          networksDataIsLoading={networksDataIsLoading}
          scrollContainerRef={scrollContainerRef}
        />
        <NoSimsModal ref={noSimsModalRef} />
      </div>
    </div>
  );
};

export const SimcardsView = React.memo(SimcardsViewComponent) as typeof SimcardsViewComponent;
