import ErrorMessage from '@components/ErrorMessage';
import { Loader } from '@components/Loader';
import { ORIGIN, STRIPE_PUBLISHABLE_KEY } from '@core/constants';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { useCreateFuturePaymentMutation } from '../billing-api-slice';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, {
  locale: 'en',
});

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setProcessing(true);
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${ORIGIN}/dashboard/billing`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setProcessing(false);
  };

  return (
    <div className="w-100">
      <Form>
        <PaymentElement onReady={() => setLoading(false)} />
        {loading ? (
          <Loader className="mt-4" size={3} />
        ) : (
          <>
            <div className="d-flex justify-content-center mt-4">
              {processing ? (
                <Loader className="mt-2" />
              ) : (
                <Button className="w-100" disabled={!stripe} onClick={handleSubmit}>
                  Save card
                </Button>
              )}
            </div>
            <ErrorMessage message={errorMessage} dismissible onClose={() => setErrorMessage('')} />
          </>
        )}
      </Form>
    </div>
  );
};

const SetupIntent = ({ isOpen, handleCloseModal }) => {
  const customerId = useCustomerId();
  const [clientSecret, setClientSecret] = useState('');
  const [processing, setProcessing] = useState(false);

  const [createFuturePayment, createFuturePaymentResponse] = useCreateFuturePaymentMutation();

  useEffect(() => {
    if (customerId) {
      createFuturePayment();
      if (isOpen && !clientSecret) {
        setProcessing(() => true);
        if (createFuturePaymentResponse.data) {
          setClientSecret(() => createFuturePaymentResponse.data?.client_secret);
        }
        setProcessing(() => false);
      }
    }
  }, [isOpen, customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal show={isOpen} onHide={handleCloseModal} centered>
      <div className="d-flex justify-content-end pt-3 me-3">
        <span onClick={handleCloseModal} className="fe fe-x cursor-pointer" />
      </div>
      <Modal.Body className="pt-0 px-5">
        {processing && <Loader className="mt-4" size={3} />}
        {!processing && clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
            }}
          >
            <SetupForm />
          </Elements>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SetupIntent;
