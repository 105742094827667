import ErrorMessage from '@components/ErrorMessage';
import { Loader } from '@components/Loader';
import { Formik } from 'formik';
import { useState } from 'react';
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { useCreateBillingInvoiceMutation, useGetBillingInvoiceQuery } from '../../billing-api-slice';
import AmountSelector from '../AmountSelector';

interface FormikProps {
  po_number: string;
  invoice_ref: string;
  division: string;
  payment_days: number;
  amount_in_cents: string;
  invoice_attendee: string;
  email_invoice: string;
}

const RechargeByInvoice = ({ isOpen, handleClose, currencySign }) => {
  const [amount, setAmount] = useState('');

  interface AmountValues {
    float?: number;
  }

  const [createInvoice, invoiceResult] = useCreateBillingInvoiceMutation();

  const [amountValues, setAmountValues] = useState<AmountValues>({});
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const customerId = useCustomerId();
  const { data: invoice, isLoading, isFetching } = useGetBillingInvoiceQuery(undefined, { skip: !customerId });

  const handleSubmit = async (values) => {
    setErrorMessage('');
    if (!isAmountValid || !amountValues.float) {
      return setErrorMessage('Amount not valid!');
    }
    await createInvoice({
      data: {
        ...values,
        amount_in_cents: amountValues.float * 100,
      },
    });

    if (invoiceResult.error) {
      setErrorMessage(invoiceResult.error as string);
    } else {
      handleClose();
    }
  };

  const formikInitialValues: FormikProps = {
    po_number: '',
    invoice_ref: '',
    division: '',
    payment_days: invoice?.payment_days,
    amount_in_cents: '',
    invoice_attendee: invoice?.invoice_attendee,
    email_invoice: invoice?.email_invoice,
  };

  return (
    <Modal centered show={isOpen} backdrop="static" onHide={handleClose}>
      <Modal.Header className="border-0 pb-0 d-flex justify-content-end">
        <span onClick={handleClose} className="fe fe-x cursor-pointer fs-1 mt-n2 me-n3" />
      </Modal.Header>
      <Modal.Body className="pt-0">
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            <h2 className="d-flex justify-content-center mb-3">Recharge by invoice</h2>
            <div className="mt-2 mb-4">
              Your Simbase account can be recharged requesting an invoice. Make sure your billing details are correct.
              Add additional info to the invoice below. Upon receiving the payment, the account balance is recharged
              with the invoiced amount.
            </div>
            <Formik
              initialValues={formikInitialValues}
              onSubmit={handleSubmit}
              validate={(values) => {
                interface Error {
                  po_number?: string;
                  invoice_ref?: string;
                  division?: string;
                  payment_days?: string;
                  amount_in_cents?: string;
                  invoice_attendee?: string;
                  email_invoice?: string;
                }
                const errors: Error = {};
                if (!values.payment_days) {
                  errors.payment_days = 'Required';
                }
                if (!values.email_invoice) {
                  errors.email_invoice = 'Required';
                }
                if (!/^[1-9][0-9]?$|^100$/.test(values.payment_days)) {
                  errors.payment_days = 'Must be in range of 10 to 90';
                }
                return errors;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                // setFieldValue,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Purchase Order (PO) Number</Form.Label>
                    <InputGroup className="p-0">
                      <FormControl
                        value={values.po_number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="string"
                        name="po_number"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Invoice reference</Form.Label>
                    <InputGroup className="p-0">
                      <FormControl
                        value={values.invoice_ref}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="string"
                        name="invoice_ref"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Department</Form.Label>
                    <InputGroup className="p-0">
                      <FormControl
                        value={values.division}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="string"
                        name="division"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Payment Terms</Form.Label>
                    <InputGroup className="p-0">
                      <FormControl
                        value={values.payment_days}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="string"
                        name="payment_days"
                      />
                    </InputGroup>
                    <span className="text-danger">
                      {errors.payment_days && touched.payment_days && (errors.payment_days as React.ReactNode)}
                    </span>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Attention To</Form.Label>
                    <InputGroup className="p-0">
                      <FormControl
                        value={values.invoice_attendee}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="string"
                        name="invoice_attendee"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Invoice Email Address</Form.Label>
                    <InputGroup className="p-0">
                      <FormControl
                        value={values.email_invoice}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="string"
                        name="email_invoice"
                      />
                    </InputGroup>
                    <span className="text-danger">
                      {errors.email_invoice && touched.email_invoice && (errors.email_invoice as React.ReactNode)}
                    </span>
                  </Form.Group>
                  <div className="mb-3">
                    <AmountSelector
                      value={amount}
                      setValue={setAmount}
                      setValues={setAmountValues}
                      prefix={currencySign}
                      setIsValueValid={setIsAmountValid}
                      label="Amount"
                      min={100}
                      limit={null}
                    />
                  </div>
                  <div className="text-muted">{`There is a 7.50${currencySign} surcharge for this payment method.`}</div>
                  <div className="d-flex justify-content-center">
                    <Button className="mt-3 lift" variant="primary" type="submit" disabled={isSubmitting}>
                      Request Invoice
                    </Button>
                  </div>
                  <ErrorMessage
                    className="mt-4"
                    alertClassname="mb-0"
                    message={errorMessage}
                    dismissible
                    onClose={() => setErrorMessage('')}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RechargeByInvoice;
