import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CustomerState = {
  customerId: string | null;
};

const initialState: CustomerState = {
  customerId: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerId: (state, action: PayloadAction<string | null>) => {
      state.customerId = action.payload;
    },
    clearCustomerId: (state) => {
      state.customerId = null;
    },
  },
});

export const { setCustomerId, clearCustomerId } = customerSlice.actions;
export default customerSlice;
