import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { ReadWriteRadio } from '@components/Atoms/ReadWriteRadio/ReadWriteRadio';
import { ToggleButton } from '@components/Atoms/ToggleButton/ToggleButton';
import { IP_REGEX_WITH_OPITIONAL_CIDR } from '@core/constants';
import { DOCUMENTATION_LINK } from '@core/constants/links';
import { useCreateApiKeyMutation } from '@modules/dashboard/integrations/integrations-api-slice';
import { TransparentInput } from '@modules/dashboard/simCard';
import { convertNumberToUTC } from '@utils/convertNumberToUtc/convertNumberToUtc';
import { transformPermissionsData } from '@utils/integrationUtils';
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Permission, PermissionNode, PermissionTree } from 'src/types/types';
import { ApiBaseModal } from '../ApiBaseModal/ApiBaseModal';
import { InfoToolTip } from '../InfoToolTip/InfoToolTip';

type FormState = {
  apiKeyValue: string;
  ipRestrictions: string;
  expiration: string;
  advancedOpen: boolean;
  permissions: Record<string, Permission>;
  request: Record<string, string>;
  createButtonDisabled: boolean;
  ipIsNotValid: boolean;
  expirationIsNotValid: boolean;
};
type CreateApiKeyModalProps = {
  open: boolean;
  closeModal: () => void;
  data: { resource: string; permissions: Permission[] }[];
  openSuccessModal: (apiKey: string) => void;
};

const INITIAL_STATE: FormState = {
  apiKeyValue: '',
  ipRestrictions: '',
  expiration: '',
  advancedOpen: false,
  permissions: {},
  request: {},
  createButtonDisabled: true,
  ipIsNotValid: false,
  expirationIsNotValid: false,
};

const validateIpRestrictions = (ip: string): boolean => IP_REGEX_WITH_OPITIONAL_CIDR.test(ip);

const useCreateApiKeyForm = (initialState: FormState) => {
  type Action =
    | { type: 'SET_API_KEY_VALUE'; payload: string }
    | { type: 'SET_IP_RESTRICTIONS'; payload: string }
    | { type: 'SET_EXPIRATION'; payload: string }
    | { type: 'TOGGLE_ADVANCED' }
    | {
        type: 'SET_PERMISSIONS';
        payload: Record<string, Permission> | ((prev: Record<string, Permission>) => Record<string, Permission>);
      }
    | { type: 'SET_REQUEST'; payload: Record<string, string> }
    | { type: 'SET_CREATE_BUTTON_DISABLED'; payload: boolean }
    | { type: 'SET_IP_IS_NOT_VALID'; payload: boolean }
    | { type: 'SET_EXPIRATION_IS_NOT_VALID'; payload: boolean };

  const reducer = (state: FormState, action: Action): FormState => {
    switch (action.type) {
      case 'SET_API_KEY_VALUE':
        return { ...state, apiKeyValue: action.payload };
      case 'SET_IP_RESTRICTIONS':
        return { ...state, ipRestrictions: action.payload };
      case 'SET_EXPIRATION':
        return { ...state, expiration: action.payload };
      case 'TOGGLE_ADVANCED':
        return { ...state, advancedOpen: !state.advancedOpen };
      case 'SET_PERMISSIONS':
        const newPermissions =
          typeof action.payload === 'function' ? action.payload(state.permissions) : action.payload;
        return { ...state, permissions: newPermissions };
      case 'SET_REQUEST':
        return { ...state, request: action.payload };
      case 'SET_CREATE_BUTTON_DISABLED':
        return { ...state, createButtonDisabled: action.payload };
      case 'SET_IP_IS_NOT_VALID':
        return { ...state, ipIsNotValid: action.payload };
      case 'SET_EXPIRATION_IS_NOT_VALID':
        return { ...state, expirationIsNotValid: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};

const PermissionItem: React.FC<{
  item: PermissionNode;
  level?: number;
  selections: Record<string, Permission>;
  onSelectionChange: (name: string, value: Permission) => void;
  index: number;
}> = ({ item, level = 0, selections, onSelectionChange, index }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectionChange = (value: Permission) => {
    onSelectionChange(item.name, value);
    if (item.children.length) {
      setIsOpen(true);
    }
  };

  const getItemStyles = () => {
    if (level === 0) {
      return `tw-flex tw-justify-between tw-px-6 tw-py-2.5 tw-bg-white tw-border-t-0 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-sb-blue-grey-300 ${level === 0 && !isOpen ? 'tw-pb-5' : ''}`;
    } else if (level === 1) {
      return `tw-flex tw-justify-between ${index === 0 ? '' : 'tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-sb-blue-grey-300'}   tw-py-2.5`;
    } else {
      return 'tw-flex tw-justify-between t';
    }
  };

  const getLabelStyles = () => {
    if (level === 0) {
      return 'tw-text-base tw-font-semibold tw-text-sb-blue-300';
    } else if (level === 1) {
      return 'tw-text-base tw-font-semibold tw-text-sb-blue-grey-600';
    } else {
      return 'tw-pl-10 tw-text-sm tw-font-normal tw-text-sb-blue-grey-600';
    }
  };

  return (
    <div className={`${level === 1 ? 'tw-flex tw-flex-col tw-px-6' : ''}`}>
      <div className={getItemStyles()}>
        <div onClick={() => item.children.length && setIsOpen(!isOpen)} className="tw-flex tw-items-center tw-gap-3">
          <span className={getLabelStyles()}>{t(`integrations.modal.${item.name}`)}</span>
          {!!item.children.length && level < 2 && (
            <div
              className={`tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-sm ${level === 0 ? 'tw-bg-sb-blue-grey-100' : ''}`}
            >
              <Icon name={isOpen ? 'chevron_down' : 'chevron_right'} height={isOpen ? 4 : 6} width={isOpen ? 6 : 4} />
            </div>
          )}
        </div>
        <div className="tw-flex tw-w-[199px] tw-items-center">
          <ReadWriteRadio
            tabs={item.options.map((o) => t(o))}
            value={selections[item.name] || 'None'}
            onChange={handleSelectionChange}
            dropshadow={level === 0}
          />
        </div>
      </div>
      {isOpen && item.children && (
        <div className={level === 0 ? 'tw-flex tw-flex-col' : 'tw-flex tw-flex-col tw-gap-2.5 tw-py-2.5'}>
          {item.children.map((child, index) => (
            <PermissionItem
              key={child.name}
              item={child}
              level={level + 1}
              selections={selections}
              onSelectionChange={onSelectionChange}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const CreateApiKeyModal = ({ open, closeModal, data, openSuccessModal }: CreateApiKeyModalProps) => {
  const { t } = useTranslation();
  const [createApiKey] = useCreateApiKeyMutation();

  const { state, dispatch } = useCreateApiKeyForm(INITIAL_STATE);

  const permissionStructure = useMemo(() => transformPermissionsData(data), [data]);

  const handleCreateApiRequest = useCallback(
    (permissions: Record<string, Permission>) => {
      const createRequestObject = (
        children: PermissionTree | PermissionNode[],
        parentPermission: Permission | null = null,
        parentResource: string = '',
      ): Record<string, string> => {
        const requestObject: Record<string, string> = {};
        children.forEach((child) => {
          const childPermission = permissions[child.name];

          const resourceName = parentResource ? `${parentResource}.${child.name}` : child.name;

          if (parentPermission === null || parentPermission === 'null') {
            if (childPermission === 'Write') {
              requestObject[resourceName] = '*';
            } else if (childPermission === 'Read') {
              requestObject[resourceName] = 'read';
            }

            if (childPermission === 'null' && child.children.length > 0) {
              Object.assign(requestObject, createRequestObject(child.children, childPermission, resourceName));
            }
          }
        });
        return requestObject;
      };

      if (permissions.allResources === 'Write') {
        dispatch({ type: 'SET_REQUEST', payload: { '*': '*' } });
      } else if (permissions.allResources === 'Read') {
        dispatch({ type: 'SET_REQUEST', payload: { '*': 'read' } });
      } else if (permissions.allResources === 'None') {
        dispatch({ type: 'SET_REQUEST', payload: {} });
      } else {
        dispatch({ type: 'SET_REQUEST', payload: createRequestObject(permissionStructure.children) });
      }
    },
    [permissionStructure, dispatch],
  );

  const handleCreatePermissions = useCallback(
    (name: string, value: Permission) => {
      const setChildPermissions = (
        children: PermissionTree | PermissionNode[],
        parentValue: Permission,
      ): Record<string, Permission> => {
        const newPermissions: Record<string, Permission> = {};
        children.forEach((child) => {
          if (parentValue === 'Write') {
            newPermissions[child.name] = child.options.includes('write') ? 'Write' : 'Read';
          } else if (parentValue === 'Read') {
            newPermissions[child.name] = child.options.includes('read') ? 'Read' : 'None';
          } else {
            newPermissions[child.name] = 'None';
          }
          if (child.children.length > 0) {
            Object.assign(newPermissions, setChildPermissions(child.children, newPermissions[child.name]));
          }
        });

        return newPermissions;
      };

      const newPermissions = (prevPermissions: Record<string, Permission>) => {
        if (name === 'allResources') {
          const result = {
            allResources: value,
            ...setChildPermissions(permissionStructure.children, value),
          };

          return result;
        } else {
          const result = { ...prevPermissions, allResources: 'null' as Permission };
          const updateChildPermissions = (children: PermissionTree | PermissionNode[]): boolean => {
            for (const child of children) {
              if (child.name === name) {
                result[name] = value;
                if (child.children.length > 0) {
                  Object.assign(result, setChildPermissions(child.children, value));
                }
                return true;
              } else if (updateChildPermissions(child.children)) {
                result[child.name] = 'null' as Permission;
                return true;
              }
            }
            return false;
          };
          updateChildPermissions(permissionStructure.children);

          return result;
        }
      };

      dispatch({
        type: 'SET_PERMISSIONS',
        payload: newPermissions,
      });
    },
    [permissionStructure, dispatch],
  );
  const handlePermissionChange = useCallback(
    (name: string, value: Permission) => {
      handleCreatePermissions(name, value);
    },
    [handleCreatePermissions],
  );

  useEffect(() => {
    handleCreateApiRequest(state.permissions);
  }, [state.permissions, handleCreateApiRequest]);

  const generateRequestBody = useCallback(() => {
    const requestBody: {
      key_name: string;
      scopes: string[];
      allowed_ips?: string;
      expiration?: string;
    } = {
      key_name: state.apiKeyValue,
      scopes: Object.entries(state.request).map(([key, value]) => `${key}:${value}`),
    };

    if (state.ipRestrictions && validateIpRestrictions(state.ipRestrictions)) {
      requestBody.allowed_ips = state.ipRestrictions;
    }

    if (state.expiration) {
      const days = parseInt(state.expiration, 10);
      if (!isNaN(days) && days > 0) {
        requestBody.expiration = convertNumberToUTC(days);
      }
    }

    return requestBody;
  }, [state.apiKeyValue, state.request, state.ipRestrictions, state.expiration]);

  const handleCreateApiKey = useCallback(async () => {
    const requestBody = generateRequestBody();

    if (requestBody.scopes.length > 0) {
      const pendingToast = toast.loading(t('integrations.modal.creatingApiKey'));
      try {
        const response = await createApiKey(requestBody).unwrap();
        toast.dismiss(pendingToast);
        toast.success(t('integrations.modal.apiKeyCreatedSuccess'));
        closeModal();
        openSuccessModal(response.api_key);
      } catch (error) {
        toast.dismiss(pendingToast);
        error.data.errors.forEach((item) => toast.error(`${t('integrations.modal.apiKeyCreationFailed')} ${item.msg}`));
        console.error(t('integrations.modal.apiKeyCreationFailed'), error);
      }
    }
  }, [createApiKey, generateRequestBody, closeModal, openSuccessModal, t]);

  const result = useMemo(() => transformPermissionsData(data), [data]);

  useEffect(() => {
    const ipValid = state.ipRestrictions.length === 0 || validateIpRestrictions(state.ipRestrictions);
    const expirationValid = state.expiration.length === 0 || !isNaN(Number(state.expiration));
    const formValid =
      ipValid && expirationValid && state.apiKeyValue.length > 0 && Object.keys(state.request).length > 0;

    dispatch({ type: 'SET_IP_IS_NOT_VALID', payload: !ipValid });
    dispatch({ type: 'SET_EXPIRATION_IS_NOT_VALID', payload: !expirationValid });
    dispatch({ type: 'SET_CREATE_BUTTON_DISABLED', payload: !formValid });
  }, [state.ipRestrictions, state.expiration, state.apiKeyValue, state.request]);

  const ExpirationInfoBox = useMemo(
    () => (
      <div className="tw-flex tw-max-w-56 tw-flex-col tw-items-center tw-gap-6">
        <span className="tw-text-sm">{t('integrations.modal.expirationInfo.sectionOne')}</span>
        <span className="tw-text-sm">{t('integrations.modal.expirationInfo.sectionTwo')}</span>
      </div>
    ),
    [t],
  );

  const IpRestrictionInfoBox = useMemo(
    () => (
      <div className="tw-flex tw-max-w-56 tw-flex-col tw-items-center tw-gap-6">
        <span className="tw-text-sm">{t('integrations.modal.ipRestrictionInfo.sectionOne')}</span>
        <span className="tw-text-sm">{t('integrations.modal.ipRestrictionInfo.sectionTwo')}</span>
      </div>
    ),
    [t],
  );

  const advancedSettingsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (advancedSettingsRef.current) {
      const element = advancedSettingsRef.current;
      element.style.height = state.advancedOpen ? `${element.scrollHeight}px` : '0px';
    }
  }, [state.advancedOpen]);

  return (
    <ApiBaseModal open={open} onClose={closeModal} title={t('integrations.modal.title')} clickOutsideToClose={false}>
      <div
        className={`tw-flex tw-flex-col tw-gap-6 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-p-6 ${state.advancedOpen ? '' : 'tw-pb-0'}`}
      >
        <span className="tw-text-base tw-font-semibold tw-text-sb-blue-500">{t('integrations.modal.keyName')}</span>
        <div className="tw-flex tw-flex-col tw-gap-3.5 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-sb-blue-grey-300">
          <TransparentInput
            className="tw-rounded-lg tw-border-sb-blue-grey-300 tw-px-4 tw-py-2.5 tw-placeholder-sb-blue-grey-400"
            value={state.apiKeyValue}
            onChange={(e) => dispatch({ type: 'SET_API_KEY_VALUE', payload: e.target.value })}
            placeholder={t('integrations.modal.myApiKey')}
          />
        </div>
        <div className="tw-flex tw-cursor-pointer tw-justify-between tw-bg-sb-blue-grey-25">
          <div className="tw-flex tw-items-center tw-gap-3">
            <ToggleButton
              value={state.advancedOpen}
              handleChange={() => dispatch({ type: 'TOGGLE_ADVANCED' })}
              style="green"
              wrapperClassName="tw-py-2.5"
            />
            <div onClick={() => dispatch({ type: 'TOGGLE_ADVANCED' })}>
              <span className="tw-text-base tw-font-normal tw-text-sb-blue-500">
                {t('integrations.modal.showAdvancedSettings')}
              </span>
            </div>
          </div>
        </div>
        {state.advancedOpen && (
          <div
            className={`tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-transition-opacity tw-duration-300 ${state.advancedOpen ? 'tw-opacity-100' : 'tw-opacity-0'}`}
          >
            <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded-xl tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-6 sm:tw-grid sm:tw-grid-cols-2">
              <div className="tw-flex tw-flex-grow tw-flex-col tw-gap-3.5">
                <div className="tw-flex tw-gap-2">
                  <span className="tw-sb-blue-grey-600 tw-text-sm tw-font-semibold">
                    {t('integrations.modal.ipRestrictions')}
                  </span>
                  <InfoToolTip title={IpRestrictionInfoBox}>
                    <div className="tw-flex tw-items-center">
                      <Icon name="question_info" width={14} height={14} />
                    </div>
                  </InfoToolTip>
                </div>
                <TransparentInput
                  className="tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 tw-placeholder-sb-blue-grey-400"
                  value={state.ipRestrictions}
                  onChange={(e) => dispatch({ type: 'SET_IP_RESTRICTIONS', payload: e.target.value })}
                  placeholder="0.0.0.0/0"
                />
                {state.ipIsNotValid && (
                  <span className="tw-text-sm tw-font-light tw-text-sb-orange-300">
                    {t('integrations.modal.invalidIp')}
                  </span>
                )}
              </div>
              <div className="tw-flex tw-flex-grow tw-flex-col tw-gap-3.5">
                <div className="tw-flex tw-gap-2">
                  <span className="tw-sb-blue-grey-600 tw-text-sm tw-font-semibold">
                    {t('integrations.modal.expiration')}
                  </span>
                  <InfoToolTip title={ExpirationInfoBox}>
                    <div className="tw-flex tw-items-center">
                      <Icon name="question_info" width={14} height={14} />
                    </div>
                  </InfoToolTip>
                </div>

                <TransparentInput
                  className="tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 tw-placeholder-sb-blue-grey-400"
                  value={state.expiration}
                  onChange={(e) => dispatch({ type: 'SET_EXPIRATION', payload: e.target.value })}
                  placeholder="31"
                />
                {state.expirationIsNotValid && (
                  <span className="tw-text-sm tw-font-light tw-text-sb-orange-300">
                    {t('integrations.modal.invalidExpiration')}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="tw-flex tw-flex-col tw-gap-6 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-solid tw-border-sb-blue-grey-300 tw-py-5 tw-pl-6 tw-pr-[39px]">
        <div className="tw-flex tw-items-end tw-justify-between">
          <div className="tw-flex tw-items-end tw-gap-2">
            <span className="tw-text-base tw-font-semibold tw-text-sb-blue-500">
              {t('integrations.modal.resources')}
            </span>
            <Button
              asLink={true}
              variant="borderless"
              href={DOCUMENTATION_LINK}
              newTab={true}
              className="tw-flex tw-gap-1 tw-text-sb-blue-300"
              size="small"
            >
              {t('integrations.modal.viewApiDocs')}
              <Icon name="link" height={10} width={10} className="!tw-text-sb-blue-300" />
            </Button>
          </div>
          <span className="tw-inline-block tw-w-[184px] tw-font-semibold tw-text-sb-blue-500">
            {t('integrations.modal.permissions')}
          </span>
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        <PermissionItem
          key={result.name}
          item={result}
          selections={state.permissions}
          onSelectionChange={handlePermissionChange}
          index={0}
        />
      </div>
      <div className="tw-flex tw-flex-col-reverse tw-items-center tw-justify-center tw-gap-5 tw-bg-sb-blue-grey-100 tw-p-6 sm:tw-flex-row">
        <Button className="tw-w-full tw-flex-grow" variant="outline" onClick={closeModal}>
          {t('button.cancel')}
        </Button>
        <Button
          className="tw-w-full tw-flex-grow"
          variant="primaryDark"
          onClick={handleCreateApiKey}
          disabled={state.createButtonDisabled}
        >
          {t('integrations.modal.createApiKey')}
        </Button>
      </div>
    </ApiBaseModal>
  );
};
