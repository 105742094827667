import { SelectBox } from '@components/Atoms/SelectBox';
import { ROLES } from '@core/constants';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';

interface IUser {
  name?: string;
  email?: string;
  role?: string;
}

interface UserFormProps {
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  user?: IUser;
}

const UserForm = ({ onSubmit, isEdit = false, user = {} }: UserFormProps) => {
  return (
    <Formik
      initialValues={
        !isEdit
          ? {
              name: '',
              role: '',
            }
          : {
              name: user?.name,
              role: user?.role,
            }
      }
      validate={(values) => {
        interface Errors {
          name?: string;
          role?: string;
        }
        const errors: Errors = {};
        if (!values.role) {
          errors.role = 'Required';
        }
        return errors;
      }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ errors, touched, setFieldValue, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tw-flex tw-justify-center">
            <span className="tw-font-semibold">{user?.name}</span>
          </div>
          <Form.Label>Role</Form.Label>
          <SelectBox
            defaultValue={user?.role ? { value: user?.role, label: user?.role } : undefined}
            onChange={(option) => setFieldValue('role', option.value)}
            options={ROLES.filter(({ role }) => role !== 'owner').map(({ role }) => ({ value: role, label: role }))}
          />
          <span className="text-danger">{errors.role && touched.role ? errors.role : ''}</span>
          <Button type="submit" variant="primary" className="w-100 mb-2 mt-4 lift" disabled={isSubmitting}>
            {isEdit ? 'Save' : 'Invite'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
