import { useEffect, useState } from 'react';
import { Button, Card, Dropdown, DropdownButton, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { Loader } from '../../../../components';
import { getSubnetSize, mapPrivateNetworksToDropdownOptions } from '../lib';
import { useGetPricingQuery } from '../network-api-slice';

const ConfigureStep = ({ onNextClick, deployProcessing }) => {
  const { t } = useTranslation();
  const customerId = useCustomerId();
  const { isSuccess: getPricingSuccess, data: getPricingData } = useGetPricingQuery(undefined, { skip: !customerId });

  const [networkName, setNetworkName] = useState('');
  const [internetAccess, setInternetAccess] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');

  useEffect(() => {
    if (getPricingSuccess) {
      const newPrivateNetworkProducts = getPricingData.products.filter(
        (product) => product?.product_type === 'private_network',
      );
      const newDropdownOptions = mapPrivateNetworksToDropdownOptions(newPrivateNetworkProducts);
      setDropdownOptions(newDropdownOptions);
      setCurrencySymbol(getPricingData.currency_symbol || '');
      if (newDropdownOptions.length > 0 && !selectedOption) {
        setSelectedOption(newDropdownOptions[0]);
      }
    }
  }, [getPricingSuccess, getPricingData]);

  if (!getPricingSuccess) {
    return <div>{t('button.isLoading')}</div>;
  }

  return (
    <>
      {!!selectedOption && (
        <div className="d-flex w-100 justify-content-center mt-5">
          <div className="d-flex w-100 me-3">
            <Card className="w-100 h-100 mb-0 info-card p-3">
              <Card.Body className="py-3">
                <Form>
                  <Form.Group className="mb-3" onClick={(e) => e.stopPropagation()}>
                    <Form.Label>{t('privateNetwork.configure.chooseNetwork')}</Form.Label>
                    <InputGroup className="p-0">
                      <FormControl
                        maxLength={30}
                        value={networkName}
                        onChange={(e) => setNetworkName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Label className="mt-1 mb-2">{t('privateNetwork.configure.selectDevices')}</Form.Label>
                  <div className="d-flex w-100 mb-3">
                    <div>
                      <DropdownButton variant="outline-primary" title={selectedOption.value}>
                        {dropdownOptions.map((option) => (
                          <Dropdown.Item key={option.key} onClick={() => setSelectedOption(option)} href="#">
                            {option.value}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>
                  </div>
                  <Form.Label className="m-0">{t('privateNetwork.configure.needInternet')}</Form.Label>
                  <div className="btn-group-toggle pt-2 pb-3">
                    <input
                      type="radio"
                      className="btn-check"
                      checked={internetAccess}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onChange={() => {}}
                    />
                    <label className="btn btn-white me-3 radio-button-network" onClick={() => setInternetAccess(true)}>
                      Yes
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      checked={!internetAccess}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onChange={() => {}}
                    />
                    <label className="btn btn-white radio-button-network" onClick={() => setInternetAccess(false)}>
                      No
                    </label>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
          <div className="d-flex w-30">
            <Card className="w-100 h-100 mb-0 info-card">
              <Card.Header>
                <h4 className="card-header-title text-capitalize">{t('summary')}</h4>
              </Card.Header>
              <Card.Body className="py-3">
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between border-bottom-not-last py-3">
                    <h4 className="card-header-title align-items-center d-flex text-capitalize">
                      {t('privateNetwork.configure.networkSize')}
                    </h4>
                    <h4 className="card-header-title align-items-center d-flex text-capitalize">{t('cost')}</h4>
                  </div>
                  <div className="d-flex justify-content-between border-bottom-not-last py-3">
                    <h4 className="card-header-title align-items-center d-flex text-capitalize text-nowrap">
                      {selectedOption.value}
                    </h4>
                    <span className="text-muted text-end">
                      {`${currencySymbol}${Number(selectedOption.price).toFixed(2)}`}
                      <div className="text-nowrap">{`${t('privateNetwork.configure.per')} ${selectedOption.unit}`}</div>
                    </span>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex justify-content-between border-bottom-not-last">
                  <h4 className="card-header-title align-items-center d-flex text-capitalize">
                    {t('privateNetwork.configure.billedDaily')}
                  </h4>
                  <h4 className="card-header-title align-items-center d-flex text-capitalize">
                    {`${currencySymbol}${Number(selectedOption.price).toFixed(2)}`}
                  </h4>
                </div>
              </Card.Footer>
              <Card.Footer className="d-flex justify-content-center">
                {deployProcessing ? (
                  <Loader size={2.5} />
                ) : (
                  <Button
                    className="text-nowrap"
                    onClick={() =>
                      onNextClick({
                        network_name: networkName,
                        subnet_size: getSubnetSize(selectedOption.value),
                        internet_access: internetAccess ? 'yes' : 'no',
                      })
                    }
                  >
                    {t('privateNetwork.configure.deploy')}
                  </Button>
                )}
              </Card.Footer>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfigureStep;
