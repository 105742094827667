import { MenuItem } from '@headlessui/react';
import useCountries from '@utils/useCountries';
import { CountryCode } from 'libphonenumber-js';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BaseDropdown } from '../BaseDropdown/BaseDropdown';
import { Icon } from '../Icon/Icon';

export type Country = {
  countryCode: CountryCode;
  dialCode: string;
  name: string;
};

type PhoneInputProps = {
  phoneNumber?: string;
  defaultCountryCode?: string;
  onChange?: (value: string, country: Country) => void;
  placeholder?: string;
  containerClassName?: string;
  inputClassName?: string;
  renderButtonTabIndex?: number;
  phoneTabIndex?: number;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errorBorder?: boolean;
};

export const PhoneInput: FC<PhoneInputProps> = ({
  phoneNumber,
  defaultCountryCode = 'US',
  onChange,
  placeholder = 'Enter phone number',
  containerClassName = '',
  inputClassName = '',
  renderButtonTabIndex = 0,
  phoneTabIndex = 0,
  onBlur,
  errorBorder = false,
}) => {
  const allCountries = useCountries();
  const [selectedCountryCode, setSelectedCountryCode] = useState<CountryCode | null>(
    defaultCountryCode.toUpperCase() as CountryCode,
  );

  useEffect(() => {
    setSelectedCountryCode(defaultCountryCode.toUpperCase() as CountryCode);
  }, [defaultCountryCode]);

  const [value, setValue] = useState<string>(phoneNumber || '');
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const selectedCountry = useMemo(() => {
    const found = allCountries.find((c) => c.countryCode === selectedCountryCode);
    return found || allCountries[0];
  }, [selectedCountryCode, allCountries]);

  const handleSelectCountry = useCallback(
    (country: Country) => {
      setSelectedCountryCode(country.countryCode);
      onChange?.(value, country);
      setTimeout(() => {
        if (phoneInputRef.current) {
          phoneInputRef.current.focus();
        }
      }, 0);
    },
    [onChange, value],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue, selectedCountry);
    }
  };

  const getFlagUrl = (iso2: string) => `https://flagcdn.com/${iso2.toLowerCase()}.svg`;

  const filterCountries = useCallback(
    (filterQuery: string) => {
      const lower = filterQuery.toLowerCase();
      return allCountries.filter(
        (c) =>
          c.name.toLowerCase().includes(lower) ||
          c.dialCode.includes(lower) ||
          c.countryCode.toLowerCase().includes(lower),
      );
    },
    [allCountries],
  );

  return (
    <div className={`tw-flex tw-items-center tw-rounded-md tw-bg-sb-blue-grey-25 ${containerClassName}`}>
      <BaseDropdown
        anchorPosition="bottom start"
        showFilter={true}
        filterPlaceholder="country or code"
        menuButtonClassName="tw-flex focus-within:tw-shadow-input tw-items-center tw-h-[38px] tw-justify-between tw-border tw-rounded-e-none tw-border-solid !tw-border-sb-blue-grey-300 focus:!tw-border-sb-blue-300 active:!tw-border-sb-blue-300 focus-visible:tw-outline-none focus:tw-ring-0 tw-w-[110px] tw-appearance-none"
        menuItems={(filterQuery) => {
          const filtered = filterCountries(filterQuery);
          return (
            <>
              {filtered.map((country) => (
                <MenuItem key={country.countryCode}>
                  {({ active }) => (
                    <div
                      className={`tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-2 tw-px-3 tw-py-2 hover:tw-bg-sb-blue-grey-25 focus:tw-outline-none focus-visible:tw-outline-none ${
                        active ? 'tw-bg-sb-blue-grey-25' : ''
                      }`}
                      onClick={() => {
                        handleSelectCountry(country);
                      }}
                    >
                      <img
                        src={getFlagUrl(country.countryCode)}
                        alt={country.name}
                        className="tw-h-4 tw-w-6 tw-object-cover"
                      />
                      <span className="tw-text-sb-blue-grey-700 tw-text-sm">{country.dialCode}</span>

                      <span className="tw-text-sm tw-text-sb-blue-grey-600">{country.name}</span>
                    </div>
                  )}
                </MenuItem>
              ))}
            </>
          );
        }}
      >
        {(open, _) => {
          return (
            <div
              className={`tw-flex tw-w-[110px] tw-items-center tw-justify-between tw-gap-2 tw-rounded-l tw-border tw-border-solid ${errorBorder ? '!tw-border-sb-orange-400 !tw-border-r-transparent' : 'tw-border-transparent'} tw-p-2 focus:!tw-border-sb-blue-300 focus-visible:tw-outline-none focus-visible:tw-ring-0`}
              tabIndex={renderButtonTabIndex}
            >
              <img
                src={getFlagUrl(selectedCountry.countryCode)}
                alt={selectedCountry.name}
                className="tw-h-4 tw-w-6 tw-object-cover"
              />
              <span className="tw-text-sb-blue-grey-700 tw-text-sm tw-font-medium">{selectedCountry.dialCode}</span>
              <Icon name={open ? 'chevron_up' : 'chevron_down'} className="tw-text-sb-blue-grey-700 tw-text-sm" />
            </div>
          );
        }}
      </BaseDropdown>

      <input
        type="text"
        className={`focus:tw-shadow-input tw-flex-1 tw-rounded-r tw-border tw-border-solid tw-border-l-transparent ${errorBorder ? '!tw-border-sb-orange-400 !tw-border-l-transparent focus:!tw-border-sb-blue-300' : 'tw-border-sb-blue-grey-300'} tw-bg-transparent tw-px-2 tw-py-2 focus:tw-border-sb-blue-300 focus:tw-outline-none focus:tw-ring-0 ${inputClassName}`}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        tabIndex={phoneTabIndex}
        ref={phoneInputRef}
        onBlur={onBlur}
      />
    </div>
  );
};
