import { IconName } from '@assets/img/icons/icons';
import { Input } from '@headlessui/react';
import { forwardRef, HTMLInputAutoCompleteAttribute, useState } from 'react';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

type TransparentInputProps = {
  placeholder: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: IconName;
  className?: string;
  buttonText?: string;
  buttonFunction?: () => void;
  type?: string;
  toggleVisibility?: boolean;
  onToggle?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  tabIndex?: number;
  autoComplete?: HTMLInputAutoCompleteAttribute;
  inputRef?: React.RefObject<HTMLInputElement>;
  iconClasses?: string;
  name?: string;
  readOnly?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  wrapperClassName?: string;
};

const TransparentInput = forwardRef<HTMLInputElement, TransparentInputProps>(
  (
    {
      placeholder,
      value,
      onChange,
      icon,
      className = '',
      buttonText,
      buttonFunction,
      type = 'text',
      toggleVisibility = false,
      onToggle,
      onBlur,
      tabIndex,
      autoComplete,
      inputRef,
      iconClasses = '',
      name,
      readOnly = false,
      onFocus,
      wrapperClassName = '',
    },
    ref,
  ) => {
    const [isHovered, setIsHovered] = useState(false);

    const defaultColor = '#A9BED8';
    const hoverColor = '#2467CA';
    return (
      <div className={`tw-relative tw-w-full ${wrapperClassName}`}>
        <Input
          type={type}
          className={`tw-w-full tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-py-2 tw-pl-3 tw-pr-10 tw-text-sm tw-text-gray-600 tw-placeholder-gray-400 tw-outline-none focus:tw-border-gray-300 ${className}`}
          placeholder={placeholder}
          aria-label={placeholder}
          value={value}
          onChange={onChange}
          ref={inputRef ?? ref}
          onBlur={onBlur}
          tabIndex={tabIndex}
          autoComplete={autoComplete}
          name={name}
          readOnly={readOnly}
          onFocus={onFocus}
        />
        {icon && (
          <div
            className={`tw-pointer-events-none tw-absolute tw-inset-y-0 ${toggleVisibility ? 'tw-right-10' : 'tw-right-2'} tw-top-1 tw-flex tw-h-[28px] tw-w-[28px] tw-items-center tw-justify-center tw-rounded-[4px] tw-bg-sb-blue-grey-50 ${iconClasses}`}
          >
            <Icon name={icon} />
          </div>
        )}
        {toggleVisibility && (
          <Button
            type="button"
            variant="stripped"
            className="tw-focus:outline-none tw-absolute tw-inset-y-0 tw-right-4 tw-top-1/2 tw-flex tw--translate-y-1/2 tw-items-center tw-justify-center tw-pr-3"
            onClick={onToggle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            aria-label="Toggle password visibility"
          >
            <Icon name={type === 'password' ? 'eye_off' : 'eye'} strokeColor={isHovered ? hoverColor : defaultColor} />
          </Button>
        )}
        {buttonText && !icon && (
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-justify-center tw-pr-3">
            <div
              className="tw-cursor-pointer tw-text-sb-blue-300 tw-underline tw-underline-offset-4"
              onClick={buttonFunction}
            >
              {buttonText}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default TransparentInput;
