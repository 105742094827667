export const BASE_URL = import.meta.env.VITE_REACT_APP_API;
export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const STATUSPAGE_URL = 'https://www.simbasestatus.com/api/v2';
export const SUPPORTPAGE_URL = 'https://support.simbase.com';
export const PUBLIC_ENDPOINTS = ['summary.json'];
export const MAPBOX_KEY =
  'pk.eyJ1IjoiZGF2aWRuZXR0ZW4iLCJhIjoiY2tydWV4Z2x6MTFrdTJvbnBhbzUxc3NlaCJ9.eahiSgI3opbANjCOaUI5rw';
export const ORIGIN = window.location.origin;

export const FILTER_STATE = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  DELETED: 'deleted',
  INSUFFICIENT_FUNDS: 'insufficient funds',
};

export enum SIM_STATE {
  ENABLED = 'enabled',
  ENABLING = 'enabling',
  DISABLED = 'disabled',
  DISABLING = 'disabling',
  DELETED = 'deleted',
  DELETING = 'deleting',
  BLOCKED = 'blocked',
  INSUFFICIENT_FUNDS = 'insufficient funds',
}

export enum IMEI_LOCK_STATE {
  OFF = 'off',
  ON = 'on',
}

export const PAGE_SIZE_OPTIONS = [
  { value: 15, label: '15 per page' },
  { value: 25, label: '25 per page' },
  { value: 50, label: '50 per page' },
];

export const SMS_INBOX_POLL_TIME = 5000; // 5 seconds
export const GET_SIM_DETAILS_POLL_TIME = 10000; // 10 seconds
export const GET_NETWORK_DETAILS_POLL_TIME = 3500; // 3.5 seconds

export const ACTIVATE_SIMS_MAX_SIMS = 50;

export const VIEW_PERMISSIONS = {
  HOME: 'HOME',
  SIMCARDS: 'SIMCARDS',
  PRIVATE_NETWORKS: 'PRIVATE_NETWORKS',
  BILLING: 'BILLING',
  INTEGRATIONS: 'INTEGRATIONS',
  ORGANIZATION: 'ORGANIZATION', // Tab inside Settings Menu Item
};

export const ROLES = [
  {
    role: 'owner',
    permissions: Object.keys(VIEW_PERMISSIONS),
  },
  {
    role: 'admin',
    permissions: Object.keys(VIEW_PERMISSIONS),
  },
  {
    role: 'user',
    permissions: [
      VIEW_PERMISSIONS.HOME,
      VIEW_PERMISSIONS.SIMCARDS,
      VIEW_PERMISSIONS.INTEGRATIONS,
      VIEW_PERMISSIONS.PRIVATE_NETWORKS,
    ],
  },
  {
    role: 'finance',
    permissions: [
      VIEW_PERMISSIONS.HOME,
      VIEW_PERMISSIONS.BILLING,
      VIEW_PERMISSIONS.SIMCARDS,
      VIEW_PERMISSIONS.INTEGRATIONS,
      VIEW_PERMISSIONS.PRIVATE_NETWORKS,
    ],
  },
];

export const PUBLIC_IP_SOURCE_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-3][0-2]$|[0-2][0-9]$|0?[0-9]$)/;
export const REMOTE_IP_REGEX =
  /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/;
export const PRE_SHARED_KEY_REGEX = /(?=.*([\S.]\s*){20,})(?=(^[\s\S]{0,55}$)).*/;
export const REMOTE_NETWORK_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-3][0-2]$|[0-2][0-9]$|0?[0-9]$)/;
export const IP_REGEX_WITH_OPITIONAL_CIDR =
  /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

export const PUBLIC_IP_STATE = {
  TERMINATING_PUBLIC_IP: 'Terminating public IP',
  DEPLOYING_PUBLIC_ID: 'Deploying public IP',
  DYNAMIC: 'Dynamic',
};

export const GET_NETWORK_DETAILS_POLL_INTERVAL = 1000;
export const GET_OPENVPN_POLL_INTERVAL = 15000;
export const REFRESH_SIMCARDS_AFTER_NETWORK_ASSIGN_TO_NONE = 6000;

export const NETWORK_STATE = {
  DEPLOYED: 'Deployed',
  TERMINATED: 'Terminated',
};

export const SUBSCRIPTION_PLAN_TYPE = {
  CONNECTIVITY: 'connectivity',
  SUPPORT: 'support',
};

export const HOSTNAME = {
  PRODUCTION: 'dashboard.simbase.com',
  STAGING: 'staging.d32h9f22dl31hx.amplifyapp.com',
  LOCALHOST: 'localhost',
};

export const CURRENCIES = [
  {
    iso: 'USD',
    name: 'US Dollar',
    flag: 'us',
  },
  {
    iso: 'EUR',
    name: 'Euro',
    flag: 'eu',
  },
  {
    iso: 'CAD',
    name: 'Canadian Dollar',
    flag: 'ca',
  },
  {
    iso: 'AUD',
    name: 'Australian Dollar',
    flag: 'au',
  },
  {
    iso: 'GBP',
    name: 'British Pound',
    flag: 'gb',
  },
];
