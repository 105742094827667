import { useAppDispatch, useAppSelector } from '@core/rtk-hooks';
import { useEffect } from 'react';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { fetchSimcardsThunk, updateSimcardsThunk } from '../simcards-slice';

export const useSimcards = () => {
  const customerId = useCustomerId();
  const dispatch = useAppDispatch();
  const simcardsState = useAppSelector((state) => state.simcardsState);
  const simcards = useAppSelector((state) => state.simcardsState.simcards);

  useEffect(() => {
    if (customerId) {
      if (simcardsState.simcards.length === 0) {
        dispatch(fetchSimcardsThunk());
      } else {
        dispatch(updateSimcardsThunk());
      }
    }
  }, [customerId]);

  return {
    refetch: () => dispatch(updateSimcardsThunk()),
    simcards,
    isLoading: simcardsState.isLoading,
    isFetching: simcardsState.isFetching,
    isFulfilled: simcardsState.isFulfilled,
    status: simcardsState.status,
    error: simcardsState.error,
  };
};
