import { Button } from '@components/Atoms/Button/Button';
import { IconLinkCard } from '@components/Atoms/IconLinkCard/IconLinkCard';
import { HomeHeaderCard } from '@components/Molecules/HomeHeaderCard/HomeHeaderCard';
import IconContentCard from '@components/Molecules/IconContentCard/IconContentCard';
import { ActivityFeed } from '@components/Organisms/ActivityFeed/ActivityFeed';
import { GET_STARTED, HELP } from '@core/constants/home_constants';
import { BUY_SIMS_LINK } from '@core/constants/links';
import { activityMapper } from '@utils/activityMapper/activityMapper';
import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BillingBalance } from 'src/types/types';
import { useGetActivityQuery } from '../../modules/dashboard/home/home-api-slice';

type HomeProps = {
  user: string;
  balance: BillingBalance;
  billingBalanceResponseIsLoading: boolean;
  customerId: string;
};
const Home = ({ user, balance, billingBalanceResponseIsLoading, customerId }: HomeProps) => {
  const { t } = useTranslation();
  const [helpSection, setHelpSection] = useState(null);
  const [helpSectionHeight, setHelpSectionHeight] = useState('auto');

  const updateHeight = useCallback(() => {
    if (helpSection) {
      const newHeight = helpSection.offsetHeight;
      setHelpSectionHeight(`${newHeight}px`);
    }
  }, [helpSection]);

  useEffect(() => {
    if (helpSection) {
      updateHeight();

      const resizeObserver = new ResizeObserver(updateHeight);
      resizeObserver.observe(helpSection);

      return () => resizeObserver.disconnect();
    }
  }, [helpSection, updateHeight]);

  // GetActivity
  const getActivityResponse = useGetActivityQuery(null, { refetchOnMountOrArgChange: true, skip: !customerId });
  const activityData = getActivityResponse?.data;

  const mappedActivity = useMemo(() => {
    return activityMapper(activityData?.activity, t);
  }, [activityData?.activity, t]);

  return (
    <div className="tw-flex tw-min-h-screen tw-flex-col">
      <HomeHeader
        user={user}
        balance={balance}
        billingBalanceResponseIsLoading={billingBalanceResponseIsLoading}
        t={t}
      />
      <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-px-6">
        <div className="tw-mt-[-4rem] tw-flex tw-w-full tw-max-w-[1240px] tw-flex-grow tw-flex-col tw-gap-8">
          <div
            data-testid="get-started-section"
            className="tw-grid tw-grid-cols-1 tw-gap-8 sm:tw-grid-cols-2 xl:tw-grid-cols-4"
          >
            {GET_STARTED.map((item, index) => (
              <HomeHeaderCard
                header={item?.title}
                body={item?.body}
                linkText={item?.url_text}
                href={item?.url}
                icon={item?.icon}
                key={index}
              />
            ))}
          </div>

          <div className="tw-mb-10 tw-flex tw-flex-col tw-gap-8 lg:tw-grid lg:tw-grid-cols-2">
            <div className="tw-mx-auto tw-w-full">
              <div ref={setHelpSection}>
                <HelpSection t={t} />
              </div>
            </div>
            <div className="tw-max-h-[670px]" style={{ height: helpSectionHeight }}>
              <ActivityFeed
                activities={mappedActivity ? mappedActivity : []}
                activitiesIsLoading={getActivityResponse.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

const HomeHeader = React.memo(
  ({
    user,
    balance,
    billingBalanceResponseIsLoading,
    t,
  }: {
    user: string;
    balance: BillingBalance;
    billingBalanceResponseIsLoading: boolean;
    t: TFunction<'translation', undefined>;
  }) => {
    const navigate = useNavigate();
    return (
      <div
        data-testid="home-header"
        className="tw-mb-5 tw-flex tw-w-full tw-flex-shrink-0 tw-justify-center tw-bg-sb-blue-500 tw-px-6 tw-pb-10"
      >
        <div className="tw-mb-11 tw-mt-10 tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col tw-justify-between tw-gap-5 md:tw-flex-row">
          <div className="tw-flex tw-flex-col tw-justify-center tw-gap-9">
            <span data-testid="user-greeting" className="tw-text-4xl tw-font-semibold tw-text-white">
              {t('home.welcomeBackMessage', { user: user })} 👋
            </span>
            <span className="tw-text-xl tw-text-white">{t('home.pickUp')}</span>
          </div>
          <div className="tw-flex tw-flex-shrink-0 tw-flex-col tw-items-end tw-gap-2">
            <span className="tw-text-xl tw-text-white">{t('home.balance')}</span>
            <span
              data-testid="user-balance"
              className={`tw-text-4xl tw-font-semibold tw-text-green-400 ${billingBalanceResponseIsLoading ? 'tw-animate-pulse' : ''}`}
            >
              {!billingBalanceResponseIsLoading && balance
                ? `${balance.currency_symbol}${balance.balance.toFixed(2)}`
                : '$___.__'}
            </span>
            <div className="tw-mt-1 tw-flex tw-gap-5">
              <Button
                data-testid="buy-sims-button"
                variant="outlineLight"
                size="medium"
                asLink={true}
                newTab={true}
                href={BUY_SIMS_LINK}
              >
                {t('home.buySims')}
              </Button>
              <Button
                data-testid="register-sims-button"
                variant="outlineLight"
                size="medium"
                onClick={() => {
                  navigate('/dashboard/activate-sims');
                }}
              >
                {t('home.registerSims')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

const HelpSection = React.memo(({ t }: { t: TFunction<'translation', undefined> }) => {
  const api = HELP.filter((item) => item.title === 'home.helpSection.api')[0];
  const helpCenter = HELP.filter((item) => item.title === 'home.helpSection.helpCenter')[0];
  const contactSupport = HELP.filter((item) => item.title === 'home.helpSection.contactSupport')[0];
  const blog = HELP.filter((item) => item.title === 'home.helpSection.blog')[0];

  return (
    <div data-testid="help-section" className="tw-flex tw-flex-col tw-gap-5">
      <span className="tw-text-xl tw-font-semibold tw-text-sb-blue-400">{t('home.gettingHelp')}</span>
      <IconContentCard iconName="needAssistance" iconWidth={145} iconHeight={117}>
        <div className="tw-flex tw-flex-col tw-gap-8">
          <div className="tw-flex tw-flex-col tw-gap-4">
            <span className="tw-text-xl tw-font-semibold tw-text-sb-blue-400">
              {t('home.gettingHelp.needAssistance.title')}
            </span>
            <span className="tw-text-base">{t('home.gettingHelp.needAssistance.body')}</span>
          </div>

          <div className="tw-flex tw-justify-end tw-gap-2">
            <Button
              data-testid="help-center-button"
              size="medium"
              variant="primaryDark"
              asLink={true}
              href={helpCenter?.url}
              newTab={true}
            >
              {t(helpCenter.title).toUpperCase()}
            </Button>
            <Button size="medium" variant="primaryLight" asLink={true} href={contactSupport?.url} newTab={true}>
              {t(contactSupport.title).toUpperCase()}
            </Button>
          </div>
        </div>
      </IconContentCard>
      <div className="tw-flex tw-flex-col tw-justify-between tw-gap-8 sm:tw-grid sm:tw-grid-cols-2">
        <IconLinkCard
          dataTestid="icon-link-card"
          gap="1"
          icon="apiDocumentation"
          paddingTop="2"
          paddingBottom="5"
          iconWidth={145}
          iconHeight={117}
          text={api.title}
          href={api?.url}
          className="hover:tw-border-sb-blue-300 hover:tw-bg-sb-blue-grey-200"
        />

        <IconLinkCard
          dataTestid="icon-link-card"
          gap="7"
          icon="learningCenter"
          paddingTop="0"
          paddingBottom="0"
          iconWidth={140}
          iconHeight={83}
          text={blog?.title}
          href={blog?.url}
          className="!sm:tw-py-0 tw-pb-6 tw-pt-4 hover:tw-border-sb-orange-200 hover:tw-bg-sb-orange-50"
        />
      </div>
    </div>
  );
});
