import sleepySimi from '@assets/img/icons/sleepySimi.svg';
import { format } from 'date-fns';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Activity = {
  title: string;
  body: string;
  time: string;
};

const Timeline = React.memo(({ itemCount }: { itemCount: number }) => (
  <div className="tw-absolute tw-bottom-0 tw-left-14 tw-top-0.5 tw-w-8">
    {[...Array(itemCount)].map((_, index) => (
      <svg
        key={index}
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="109"
        viewBox="0 0 8 109"
        fill="none"
        className="tw-absolute tw-left-0"
        style={{ top: `${index * 101}px` }}
      >
        <path d="M4 0V109" stroke="#DBE4F6" strokeLinejoin="round" />
      </svg>
    ))}
  </div>
));

const ActivityItem = React.memo(({ activity }: { activity: Activity }) => {
  const { title, body, time } = activity;
  const formattedDate = format(new Date(time), 'MMM dd');
  const formattedTime = format(new Date(time), 'hh:mm a');

  return (
    <li className="tw-relative tw-flex tw-flex-col tw-p-4 tw-pb-0 tw-pl-8 tw-pt-3">
      <div className="tw-absolute tw-left-4 tw-top-[-5px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="109"
          viewBox="0 0 8 109"
          fill="none"
          className="tw-absolute tw-left-0"
        >
          <circle cx="4" cy="32" r="4" fill="#2467CA" />
        </svg>
      </div>

      <div className="tw-relative tw-mb-2 tw-flex tw-items-center tw-gap-2">
        <p className="tw-mb-0 tw-inline tw-rounded-md tw-bg-sb-blue-300 tw-px-3 tw-text-base tw-font-semibold tw-text-white">
          {title}
        </p>
        <div className="tw-flex tw-items-end tw-gap-1">
          <p className="tw-mb-0 tw-mt-1 tw-text-base tw-font-bold tw-leading-6 tw-text-sb-blue-500">{formattedDate}</p>
          <p className="tw-mb-0 tw-mt-1 tw-text-xs tw-leading-[21px] tw-text-sb-blue-500">{formattedTime}</p>
        </div>
      </div>

      <p className="tw-rounded-md tw-bg-sb-blue-grey-100 tw-p-3 tw-text-sm tw-text-sb-blue-500">{body}</p>
    </li>
  );
});

const LoadingActivityItem = () => {
  return (
    <li className="tw-relative tw-flex tw-flex-col tw-p-4 tw-pb-0 tw-pl-8 tw-pt-3">
      <div className="tw-absolute tw-left-4 tw-top-[-5px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="109"
          viewBox="0 0 8 109"
          fill="none"
          className="tw-absolute tw-left-0"
        >
          <circle cx="4" cy="32" r="4" fill="#2467CA" />
        </svg>
      </div>

      <div className="tw-relative tw-mb-2 tw-flex tw-items-center tw-gap-2">
        <div className="tw-mb-0 tw-flex tw-h-6 tw-w-36 tw-animate-pulse tw-items-center tw-justify-center tw-rounded-md tw-bg-sb-blue-300 tw-px-3 tw-text-base tw-font-semibold tw-text-white">
          <div className="tw-h-1 tw-w-full tw-rounded tw-bg-sb-blue-100"></div>
        </div>
      </div>

      <div className="tw-flex tw-animate-pulse tw-items-center tw-rounded-md tw-bg-sb-blue-grey-100 tw-p-3 tw-text-sm">
        <div className="tw-h-1 tw-w-full tw-rounded tw-bg-sb-blue-grey-300"></div>
      </div>
    </li>
  );
};

const ActivityFeedComponent = ({
  activities = [],
  activitiesIsLoading,
}: {
  activities: Activity[];
  activitiesIsLoading: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div data-testid="activity-feed" className="tw-flex tw-h-full tw-flex-col">
      <h2 className="tw-text-xl tw-font-semibold tw-text-sb-blue-400">{t('home.activityFeed.title')}</h2>

      {!activitiesIsLoading && !activities.length ? (
        <div
          className="tw-relative tw-flex-grow tw-overflow-hidden tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-2 tw-shadow-sm"
          role="feed"
          aria-label={t('home.activityFeed.ariaLabel')}
        >
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-5">
            <img src={sleepySimi} alt="no activity" className="tw-w-full tw-max-w-[292px]" />
            <span className="tw-text-xl tw-text-sb-blue-grey-400">{t('noActivityYet')}</span>
          </div>
        </div>
      ) : (
        <div
          className="tw-relative tw-flex-grow tw-overflow-hidden tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-shadow-sm"
          role="feed"
          aria-label={t('home.activityFeed.ariaLabel')}
        >
          <Timeline itemCount={activities.length} />
          <div className="tw-h-full tw-overflow-auto tw-pr-2">
            <ul className="tw-divide-y tw-divide-gray-200">
              {activitiesIsLoading
                ? Array.from({ length: 10 }).map((_, index) => <LoadingActivityItem key={index} />)
                : activities.map((activity, index) => <ActivityItem key={index} activity={activity} />)}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export const ActivityFeed = React.memo(ActivityFeedComponent, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.activities, nextProps.activities) &&
    prevProps.activitiesIsLoading &&
    nextProps.activitiesIsLoading
  );
});
