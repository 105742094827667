// import { Auth } from "aws-amplify";
import { getAuthenticatedUser } from '@modules/auth/utils';
import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/react';
import { QueryReturnValue } from 'node_modules/@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BASE_URL } from './constants';

export const rtkPrepareHeaders = async (headers: Headers, { endpoint }: { endpoint: string }): Promise<Headers> => {
  if (endpoint === 'signUp' || endpoint === 'checkSignUp') {
    headers.set('Content-Type', 'application/json');
    return headers;
  }

  const user = await getAuthenticatedUser().catch(() => null);

  if (user && user?.session && user?.session?.idToken) {
    const token = user.session.idToken.toString();
    headers.set('Authorization', `Bearer ${token}`);
  } else {
    console.warn('No authenticated user found. Cannot access protected endpoint:', endpoint);

    throw new Error('Unauthenticated: Cannot access protected endpoint');
  }

  headers.set('Content-Type', 'application/json');
  return headers;
};

export const getAuthHeaders = async (): Promise<Headers> => {
  const headers = new Headers();
  const user = await getAuthenticatedUser().catch(() => null);

  if (user && user?.session && user?.session?.idToken) {
    const token = user.session.idToken.toString();
    headers.set('Authorization', `Bearer ${token}`);
  } else {
    console.warn('No authenticated user found. Skipping Authorization header.');
  }

  headers.set('Content-Type', 'application/json');
  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: rtkPrepareHeaders,
});

const sentryBaseQuery = async (args: FetchArgs, api: BaseQueryApi, extraOptions?) => {
  try {
    const result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> = await baseQuery(
      args,
      api,
      extraOptions,
    );
    Sentry.withScope(async function (scope) {
      scope.setLevel('error');
      const httpMethod = args.method ? args.method : 'GET';
      scope.setTags({
        'rtk-endpoint': api.endpoint,
        'rtk-url': args.url,
        'http-method': httpMethod,
        'request-type': api.type,
      });
      scope.setExtras({
        'rtk-endpoint': api.endpoint,
        'rtk-url': args.url,
        'http-method': httpMethod,
        'rtk-args': JSON.stringify(args),
        'request-type': api.type,
        'rtk-extraOptions': extraOptions,
      });

      if (result.error) {
        const errorName = `Status: ${result.error.status} - Function: ${api.endpoint} - RouteKey: ${
          args.method ? args.method : 'GET'
        } ${args.url}`;
        scope.setExtra('rtk-error-status', result.error.status);
        scope.setTag('alert-type', 'RTK Query - Non 200 Response');
        scope.setExtra('alert-type', 'RTK Query - Non 200 Response');
        scope.setExtra('whole-response', JSON.stringify(result));
        scope.setExtra('rtk-error-message', JSON.stringify(result.error));
        Sentry.captureException(new Error(errorName), scope);
      }
    });
    return result;
  } catch (error) {
    Sentry.withScope(async function (scope) {
      scope.setLevel('error');
      const httpMethod = args.method ? args.method : 'GET';
      scope.setTags({
        'rtk-endpoint': api.endpoint,
        'rtk-url': args.url,
        'http-method': httpMethod,
        'request-type': api.type,
      });
      scope.setExtras({
        'rtk-endpoint': api.endpoint,
        'rtk-url': args.url,
        'http-method': httpMethod,
        'rtk-args': JSON.stringify(args),
        'request-type': api.type,
        'rtk-extraOptions': extraOptions,
      });
      scope.setExtra('error-message', JSON.stringify(error));
      scope.setTag('alert-type', 'RTK Query - Unhandled Exception');
      scope.setExtra('alert-type', 'RTK Query - Unhandled Exception');
      Sentry.captureException(new Error(`Unhandled Exception: ${error}`), scope);
    });
    return error;
  }
};

export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: sentryBaseQuery,
  keepUnusedDataFor: 30,
  tagTypes: ['Home', 'Tags', 'Simcard', 'Simcards', 'BillingBalance', 'Users', 'Customer', 'Self', 'ApiKeys'],
  endpoints: () => ({}),
});
