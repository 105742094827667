import Logo from '@assets/img/icons/logo-notext.svg';
import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { LoadingModal } from '@components/Molecules/LoadingModal/LoadingModal';
import { PRIVACY, TERMS_OF_USE } from '@core/constants/links';
import { signoutOnPage } from '@modules/auth/signout';
import { getAuthenticatedUser } from '@modules/auth/utils';
import { useSignUpMutation } from '@modules/dashboard/account/account-api-slice';
import { TransparentInput } from '@modules/dashboard/simCard';
import { emailRegexCheck } from '@utils/emailRegexCheck/emailRegexCheck';
import { useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const stage = searchParams.get('stage') || '';
  const emailParam = searchParams.get('email') || '';
  const errorParam = searchParams.get('error') || '';
  const [processing, setProcessing] = useState<boolean>(false);
  const [resend, setResend] = useState<boolean>(false);
  const [lockEmail, setLockEmail] = useState<boolean>(false);
  const { signout } = signoutOnPage();
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const emailRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      emailRef.current?.focus();
    }, 500);
    return () => clearTimeout(timeout);
  }, [emailRef.current]);

  useEffect(() => {
    if (errorParam) {
      setError(true);
      setLoadingMessage(t(errorParam));
      setShowLoadingModal(true);
    }
  }, [errorParam]);

  const [email, setEmail] = useState<string>(emailParam);

  const [signUp] = useSignUpMutation();

  const isEmailValid = emailRegexCheck(email.trim());

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [countdown, setCountdown] = useState<number>(0);
  const timerIdRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    async function fetchUser() {
      const user = await getAuthenticatedUser(true);
      if (!stage) {
        if (emailParam) {
          setLockEmail(true);
          signout();
        }
        if (user?.accessToken && !emailParam) {
          navigate('/dashboard');
        }
      }
    }

    fetchUser();
  }, []);

  useEffect(() => {
    if (countdown > 0 && !timerIdRef.current) {
      timerIdRef.current = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    if (countdown === 0 && timerIdRef.current) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }

    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    };
  }, [countdown]);

  useEffect(() => {
    if (countdown > 0) {
      setCountdown(0);
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    }
  }, [email]);

  const handleSignUp = async () => {
    if (!executeRecaptcha) {
      console.error(t('errorMessage.recaptchaNotAvailable'));
      setError(true);
      setLoadingMessage(t('errorMessage.recaptchaNotAvailable'));
      setShowLoadingModal(true);
      return;
    }

    setProcessing(true);
    setLoadingMessage(t('signup.signingUp'));
    setShowLoadingModal(true);

    const recaptchaToken = await executeRecaptcha('signUp');

    try {
      await signUp({ email, recaptchaToken }).unwrap();
      setShowLoadingModal(false);
      if (!stage) {
        setSearchParams((prev) => {
          const params = new URLSearchParams(prev);
          params.set('stage', 'email_link');
          params.set('email', email);
          params.delete('error');
          return params;
        });
        setCountdown(60);
      } else {
        setResend(true);
        setCountdown(60);
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError(true);
      setLoadingMessage(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    } finally {
      setProcessing(false);
    }
  };

  const handleCloseLoadingModal = () => {
    setShowLoadingModal(false);
    setTimeout(() => {
      setError(false);
    }, 300);
  };

  return (
    <div className="tw-flex tw-min-h-screen tw-w-full tw-items-center tw-justify-center tw-bg-sb-blue-grey-100 [@media(max-height:670px)]:tw-pt-2.5">
      <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-14 tw-px-2">
        <div className="tw-flex tw-justify-center">
          <img src={Logo} alt="Logo" className="tw-h-10 tw-w-44" />
        </div>
        {!stage && (
          <div className="tw-flex tw-max-w-[570px] tw-flex-col tw-gap-11">
            <div className="xs:tw-px-16 tw-flex tw-flex-col tw-gap-10 tw-rounded-[32px] tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-4 tw-py-11">
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-4">
                <div className="tw-flex tw-justify-center">
                  <span className="tw-text-center tw-font-cal-sans tw-text-4xl tw-font-semibold tw-text-sb-blue-300">
                    {t('signup.startConnectingToday')}
                  </span>
                </div>
                <div className="">
                  <span className="tw-text-2xl tw-text-sb-blue-500">{t('signup.signUpForFreeAccount')}</span>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-11">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSignUp();
                  }}
                  className="tw-flex tw-flex-col tw-gap-6"
                >
                  <TransparentInput
                    placeholder="e.g user@simbase.com"
                    value={email}
                    onChange={(e) => {
                      if (!lockEmail) setEmail(e.target.value);
                    }}
                    readOnly={lockEmail}
                    className="focus:tw-shadow-input tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-bg-white"
                    inputRef={emailRef}
                  />
                  <Button size="xl" className="tw-w-full" disabled={!isEmailValid || processing} type="submit">
                    {t('signUp')}
                  </Button>
                </form>
                <div className="tw-flex tw-items-center tw-justify-center">
                  <span className="tw-inline-block tw-text-xl">
                    <Trans
                      i18nKey="signup.alreadyHaveAnAccountLogIn"
                      components={{
                        customButton: (
                          <button
                            className="!tw-m-0 tw-border-none tw-bg-transparent !tw-p-0 tw-font-semibold tw-text-inherit tw-text-sb-blue-300"
                            onClick={() => navigate('/auth/signin')}
                          />
                        ),
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center">
              <span className="tw-inline-block tw-px-2 tw-text-center tw-text-xl tw-font-normal tw-text-sb-blue-grey-500">
                <Trans
                  i18nKey="signup.termsOfServiceAndPrivacyPolicy"
                  components={{
                    customLink1: <a href={PRIVACY} target="_blank" rel="noopener noreferrer" />,
                    customLink2: <a href={TERMS_OF_USE} target="_blank" rel="noopener noreferrer" />,
                  }}
                />
              </span>
            </div>
          </div>
        )}
        {stage === 'email_link' && (
          <div className="tw-flex tw-flex-col tw-gap-5">
            <div className="xs:tw-px-16 tw-flex tw-flex-col tw-items-center tw-gap-10 tw-rounded-3xl tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-4 tw-py-11">
              <span className="tw-text-center tw-font-cal-sans tw-text-3xl tw-font-semibold tw-text-sb-blue-300">
                {t('signup.sentATemporaryLink')}
              </span>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignUp();
                }}
                className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-10"
              >
                <TransparentInput
                  placeholder=""
                  value={email}
                  onChange={(e) => {
                    if (!lockEmail) setEmail(e.target.value);
                  }}
                  readOnly={lockEmail}
                  className="tw-flex tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 tw-px-4 !tw-pr-3 tw-text-center focus:!tw-bg-white"
                />
                <div className="tw-inline-block">
                  <span className="tw-text-base">{t('signup.didntReceiveTheEmail')}&nbsp;</span>
                  <Button
                    variant="stripped"
                    type="submit"
                    disabled={!isEmailValid || processing || countdown > 0}
                    className="tw-font-semibold !tw-text-sb-blue-300 disabled:!tw-text-sb-blue-grey-300"
                  >
                    <span className="tw-text-base">
                      {t('resend')} {countdown > 0 && `(${countdown}s)`}
                    </span>
                  </Button>
                </div>
              </form>
            </div>
            {resend && (
              <div className="tw-flex tw-h-[52px] tw-items-center tw-gap-2.5 tw-rounded-lg tw-bg-sb-blue-300 tw-pl-2 tw-pr-2.5 !tw-text-white">
                <div className="tw-flex tw-h-9 tw-w-9 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-bg-sb-blue-50">
                  <Icon name="mail" />
                </div>
                <Trans i18nKey="signup.weSentYouANewLink" className="!tw-text-white" />
              </div>
            )}
          </div>
        )}
      </div>
      {showLoadingModal && (
        <LoadingModal
          modalOpen={showLoadingModal}
          loadingMessage={loadingMessage}
          error={error}
          onClose={handleCloseLoadingModal}
        />
      )}
    </div>
  );
};
