import { BaseDropdown } from '@components/Atoms/BaseDropdown/BaseDropdown';
import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { ModalV2 } from '@components/Atoms/ModalV2/ModalV2';
import { ROLES } from '@core/constants';
import { USER_INFO } from '@core/constants/links';
import { MenuItem } from '@headlessui/react';
import { useCreateUserMutation } from '@modules/dashboard/account/account-api-slice';
import { TransparentInput } from '@modules/dashboard/simCard';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingModal } from '../LoadingModal/LoadingModal';

type InviteModalProps = {
  open: boolean;
  closeModal: () => void;
  showSkip?: boolean;
};

type User = {
  email: string;
  role: string;
};

export const InviteModal = ({ open, closeModal, showSkip = false }: InviteModalProps) => {
  const { t } = useTranslation();
  const [createUser] = useCreateUserMutation();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const [users, setUsers] = useState<User[]>([{ email: '', role: '' }]);

  const [userErrors, setUserErrors] = useState<string[]>(['']);

  const emailRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        emailRefs.current[0]?.focus();
      }, 0);
    }
  }, [open]);

  const handleAddUser = () => {
    setUsers((prev) => [...prev, { email: '', role: '' }]);
    setUserErrors((prev) => [...prev, '']);
  };

  const handleChange = (index: number, field: keyof User, value: string) => {
    const updated = [...users];
    updated[index][field] = value;
    setUsers(updated);
  };

  const handleRoleSelect = (index: number, role: string) => {
    const user = users[index];
    const wasEmpty = !user.email.trim() && !user.role;

    handleChange(index, 'role', role);

    if (index !== 0 && wasEmpty) {
      setTimeout(() => {
        document.getElementById('inviteButton')?.focus();
      }, 0);
    } else if (index === users.length - 1) {
      handleAddUser();

      setTimeout(() => {
        const newlyCreatedIndex = users.length;
        emailRefs.current[newlyCreatedIndex]?.focus();
      }, 0);
    } else {
      emailRefs.current[index + 1]?.focus();
    }
  };

  const handleInvite = async () => {
    const filteredUsers = users.filter((u) => {
      const emailTrimmed = u.email.trim();

      if (!emailTrimmed && !u.role) return false;
      return true;
    });

    try {
      setShowLoadingModal(true);
      setLoadingMessage(t('signup.invitingMembers'));
      await Promise.all(
        filteredUsers.map(async (user) => createUser({ name: '', email: user.email, role: user.role }).unwrap()),
      ),
        setShowLoadingModal(false);
      closeModal();
    } catch (error) {
      setError(true);
      setLoadingMessage(t('errorDuringInviteProcess'));
      console.error('Error during invite process', error);
    }
  };

  const handleRemoveUser = (index: number) => {
    setUsers((prev) => prev.filter((_, i) => i !== index));
    setUserErrors((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEmailBlur = (index: number) => {
    const currentEmail = users[index].email.trim();
    const updatedErrors = [...userErrors];

    if (!emailRegex.test(currentEmail) && currentEmail !== '') {
      updatedErrors[index] = t('notAValidEmailAddress');
    } else {
      updatedErrors[index] = '';
    }

    setUserErrors(updatedErrors);
  };

  const getMenuItems = (rowIndex: number) => (_: string) => {
    const filteredRoles = ROLES.filter(({ role }) => role !== 'owner');

    return (
      <>
        {filteredRoles.map((option, i) => (
          <MenuItem
            key={i}
            as="div"
            onClick={() => {
              handleRoleSelect(rowIndex, option.role);
            }}
            className="tw-w-full"
          >
            {({ active }) => (
              <div
                className={`tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-gap-2.5 tw-rounded tw-px-3 tw-py-1 ${active ? 'tw-bg-sb-blue-grey-100' : 'tw-bg-white hover:tw-bg-sb-blue-grey-100'}`}
                role="button"
              >
                <div className="tw-flex tw-items-center tw-gap-2.5">
                  <div className="tw-w-full tw-flex-grow tw-capitalize">
                    <span>{option.role}</span>
                  </div>
                </div>
              </div>
            )}
          </MenuItem>
        ))}
      </>
    );
  };

  const renderButton = (
    open: boolean,
    selectedRole: string,
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>,
  ) => {
    const displayText = selectedRole || `${t('select')}...`;

    return (
      <div onKeyDown={onKeyDown} className="tw-flex tw-w-full tw-items-center tw-gap-2.5 tw-rounded tw-px-4 tw-py-2">
        <span className="tw-inline-block tw-truncate tw-overflow-ellipsis tw-whitespace-nowrap tw-capitalize tw-text-sb-blue-grey-600">
          {displayText}
        </span>
        <div className="tw-ml-auto tw-flex tw-items-center tw-justify-center">
          <Icon name={open ? 'chevron_up' : 'chevron_down'} />
        </div>
      </div>
    );
  };

  const isInviteDisabled = users.some((user, index) => {
    const emailTrimmed = user.email.trim();
    const hasValidEmail = emailRegex.test(emailTrimmed);
    const hasRole = !!user.role;

    if (index === 0) {
      return !hasValidEmail || !hasRole;
    }

    if (!emailTrimmed && !hasRole) {
      return false;
    }

    if ((emailTrimmed && !hasValidEmail) || (hasRole && !hasValidEmail) || (hasValidEmail && !hasRole)) {
      return true;
    }

    return false;
  });

  const handleCloseLoadingModal = () => {
    setShowLoadingModal(false);
    setTimeout(() => {
      setError(false);
      setLoadingMessage('');
    }, 300);
  };

  return (
    <div>
      <ModalV2
        modalOpen={open}
        closeModal={closeModal}
        title={t('signup.inviteMembers')}
        bottomComponent={() =>
          showSkip && (
            <Button
              variant="borderless"
              size="large"
              onClick={closeModal}
              className="tw-w-auto tw-self-center tw-text-sb-blue-300"
            >
              {t('skipForNow')}
            </Button>
          )
        }
        className="tw-max-w-[541px]"
      >
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-11 tw-pt-4">
          <span className="tw-inline-block tw-text-center">
            <Trans
              i18nKey="inviteMembers.modal.body"
              components={{ customLink: <a href={USER_INFO} target="_blank" rel="noopener noreferrer" /> }}
            />
          </span>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleInvite();
            }}
            className="tw-flex tw-flex-col tw-gap-5"
          >
            <div className="tw-flex tw-flex-col tw-gap-3.5">
              {users.map((user, index) => {
                const errorMsg = userErrors[index];
                const hasError = !!errorMsg;

                return (
                  <div key={index} className="tw-flex tw-flex-col tw-gap-1">
                    <div className="tw-flex tw-items-center tw-gap-3">
                      {users.length > 1 && (
                        <div className="tw-flex tw-h-[34px] tw-w-5 tw-items-center tw-justify-center">
                          <Button
                            variant="stripped"
                            onClick={() => handleRemoveUser(index)}
                            className="tw-text-sb-red-500 tw-flex tw-items-center tw-gap-1"
                            tabIndex={-1}
                          >
                            <Icon name="close" height={10} width={10} />
                          </Button>
                        </div>
                      )}
                      <TransparentInput
                        type="email"
                        placeholder={t('exampleEmail')}
                        value={user.email}
                        className={`focus:tw-shadow-input tw-h-[34px] tw-border tw-border-solid tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-bg-white ${
                          hasError ? 'tw-border-red-500' : ''
                        }`}
                        onChange={(e) => handleChange(index, 'email', e.target.value)}
                        onBlur={() => handleEmailBlur(index)}
                        ref={(el) => {
                          if (el) emailRefs.current[index] = el;
                        }}
                      />

                      <BaseDropdown
                        menuItems={getMenuItems(index)}
                        customMenuItemsClassName="!tw-rounded tw-p-1 !tw-pt-1 !tw-pb-1 !tw-w-[112px] tw-shrink-0"
                        menuButtonClassName="tw-border tw-h-[34px] tw-shrink-0 !tw-w-[112px] !tw-border !tw-border-solid !tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25 focus:!tw-bg-white focus:tw-shadow-input focus:!tw-border-sb-blue-300"
                      >
                        {(openDropdown, closeMenu) =>
                          renderButton(openDropdown, user.role, (e) => {
                            if (e.key === 'Tab') {
                              if (index !== 0 && !user.email.trim() && !user.role) {
                                e.preventDefault();

                                closeMenu();
                                document.getElementById('inviteButton')?.focus();
                              }
                            }
                          })
                        }
                      </BaseDropdown>
                    </div>

                    {hasError && <span className="tw-ml-6 tw-text-sm tw-text-red-500">{errorMsg}</span>}
                  </div>
                );
              })}
            </div>
            <div className="tw-flex tw-justify-center">
              <Button
                variant="borderless"
                size="medium"
                tabIndex={-1}
                onClick={handleAddUser}
                className="tw-w-full tw-text-sb-blue-300"
              >
                {t('signup.inviteAdditionalMembers')}
              </Button>
            </div>
            <Button disabled={isInviteDisabled} type="submit" id="inviteButton">
              {t('Invite')}
            </Button>
          </form>
        </div>
      </ModalV2>
      <LoadingModal
        modalOpen={showLoadingModal}
        loadingMessage={loadingMessage}
        error={error}
        onClose={handleCloseLoadingModal}
      />
    </div>
  );
};
