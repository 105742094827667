import { convertLogTimes } from '@utils/convertLogTimes/convertLogTimes';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Nav, NavItem, NavLink } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { downloadFile } from 'src/lib/utils/downloadFile';
import useIsProcessing from 'src/lib/utils/hooks/useIsProcessing';
import { Loader } from '../../../../components';
import { BASE_URL, GET_OPENVPN_POLL_INTERVAL } from '../../../../core/constants';
import { useGetSelfQuery } from '../../account/account-api-slice';
import { useGetNetworkQuery, useGetOpenVpnQuery } from '../network-api-slice';
import HeaderCards from './HeaderCards';
import TerminalController from './TerminalController';

const tabs = [
  {
    key: 'windows',
    label: 'Windows',
  },
  {
    key: 'mac',
    label: 'Mac',
  },
  {
    key: 'linux',
    label: 'Linux',
  },
];

const OpenVpn = ({ id }) => {
  const customerId = useCustomerId();
  const getSelfQueryResponse = useGetSelfQuery();
  const { data: self } = getSelfQueryResponse ?? {};

  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [downloadLoading, setDownloadLoading] = useState(null);

  // GetNetwork
  const getNetworkResponse = useGetNetworkQuery({ id }, { skip: !customerId });
  const { data: network } = getNetworkResponse;

  // GetOpenVpn
  const getOpenVpnResponse = useGetOpenVpnQuery(
    { id },
    { pollingInterval: GET_OPENVPN_POLL_INTERVAL, skip: !customerId },
  );
  const { refetch: refetchOpenVpn } = getOpenVpnResponse;

  const isProcessing = useIsProcessing([
    getOpenVpnResponse.isUninitialized,
    // getOpenVpnResponse.isFetching,
    getOpenVpnResponse.isLoading,
    getNetworkResponse.isUninitialized,
    getNetworkResponse.isFetching,
    getNetworkResponse.isLoading,
    getSelfQueryResponse.isLoading,
  ]);

  const {
    openvpn_server_uptime: uptime,
    openvpn_server_logs: logs = [],
    openvpn_server_users_online: usersOnline,
    openvpn_server_max_clients: maxClients,
    openvpn_profiles: profiles = [],
    openvpn_server_status: status,
  } = getOpenVpnResponse.data ?? {};

  const defaultTimezone = moment.tz.guess();
  const preferredTime = useRef(defaultTimezone);

  useEffect(() => {
    if (self?.preferred_timezone) {
      preferredTime.current = self.preferred_timezone;
    }
  }, [self?.preferred_timezone]);

  const formattedLogs = useRef([]);

  useEffect(() => {
    if (logs) {
      formattedLogs.current = convertLogTimes(logs, preferredTime.current);
    }
  }, [logs, preferredTime]);

  const handleDownloadClick = async (profile) => {
    try {
      setDownloadLoading(profile);
      await downloadFile(`${BASE_URL}/network/${id}/server/profile/${profile}`, { profile });
    } catch (err) {
      toast.error('Failed to download file');
    } finally {
      setDownloadLoading(null);
    }
  };

  const handleRefresh = async () => {
    await refetchOpenVpn();
  };

  const getOpenVpnDownloadLink = () => {
    // eslint-disable-next-line default-case
    switch (activeTab) {
      case tabs[0].key:
        return 'https://openvpn.net/downloads/openvpn-connect-v3-windows.msi';
      case tabs[1].key:
        return 'https://openvpn.net/downloads/openvpn-connect-v3-macos.dmg';
      case tabs[2].key:
        return 'https://openvpn.net/openvpn-client-for-linux/';
    }
  };

  const getReadInstallationGuideLink = () => {
    // eslint-disable-next-line default-case
    switch (activeTab) {
      case tabs[0].key:
        return 'https://openvpn.net/client-connect-vpn-for-windows/';
      case tabs[1].key:
        return 'https://openvpn.net/client-connect-vpn-for-mac-os/';
      case tabs[2].key:
        return 'https://openvpn.net/openvpn-client-for-linux/';
    }
  };

  return (
    <div className="d-flex flex-column overview">
      {isProcessing ? (
        <Loader />
      ) : (
        <>
          <HeaderCards id={id} />
          <div className="d-flex">
            <div className="d-flex w-70 m-0 mt-4 me-3 align-self-stretch">
              <TerminalController
                logs={formattedLogs.current}
                networkName={network?.network_name}
                username={self?.name ? self?.name : ''}
                handleRefresh={handleRefresh}
                isRefetching={getOpenVpnResponse.isFetching}
              />
            </div>
            <div className="w-30 align-self-stretch">
              <div className="mt-4 ms-3">
                <Card className="w-100">
                  <Card.Header className="mt-3">
                    <div className="d-flex">
                      <div className="avatar mb-2">
                        <img
                          src="https://simbase-static-content.s3.eu-north-1.amazonaws.com/vpn/openvpn.png"
                          className="avatar-img rounded-circle border border-4 border-card bg-light"
                          alt="..."
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ms-3 gap-1">
                        <h4 className="m-0">OpenVPN Server</h4>
                        {status === 'online' && <small className="text-success">● {status}</small>}
                        {status === 'offline' && <small className="text-danger">● {status}</small>}
                        {status !== 'offline' && status !== 'online' && (
                          <small className="text-warning">● {status}</small>
                        )}
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p-0 px-4">
                    <div>
                      <div className="d-flex justify-content-center">
                        <div className="border-end py-3 d-flex justify-content-center flex-column align-items-center w-100">
                          <h6 className="text-muted mb-2">UPTIME</h6>
                          <div className="fw-700 fs-2 text-center">{uptime}</div>
                        </div>
                        <div className="py-3 d-flex justify-content-center flex-column align-items-center w-100">
                          <h6 className="text-muted mb-2">USERS ONLINE</h6>
                          <div className="fw-700 fs-2">{`${usersOnline} / ${maxClients}`}</div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      {profiles.map((profile, index) => (
                        <div key={index}>
                          <div className="d-flex justify-content-between border-bottom-not-last py-3">
                            <h4 className="card-header-title align-items-center d-flex text-lowercase">{profile}</h4>
                            <div className="d-flex">
                              <Button
                                className="btn-white"
                                size="sm"
                                onClick={() => {
                                  handleDownloadClick(profile);
                                }}
                              >
                                {downloadLoading === profile ? (
                                  <div className="d-flex gap-2 align-items-center">
                                    Download
                                    <Loader variant="secondary" size={1} />
                                  </div>
                                ) : (
                                  'Download'
                                )}
                              </Button>
                            </div>
                          </div>
                          <hr className="m-0" />
                        </div>
                      ))}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <Card className="w-100 mb-0 info-card">
                <Card.Header className="my-2 h-100">
                  <div className="d-flex justify-content-between gap-3 flex-sm-column flex-xxl-row h-100">
                    <div className="d-flex flex-column justify-content-center gap-1">
                      <h6 className="text-uppercase text-muted mb-2 text-uppercase">openvpn connect</h6>
                      <h3 className="text-nowrap mb-0">Download VPN Client</h3>
                    </div>
                    <div className="mt-sm-3 mt-xxl-0">
                      <Nav
                        variant="tabs"
                        activeKey={activeTab}
                        className="d-flex flex-nowrap p-0"
                        onSelect={(tab) => setActiveTab(tab)}
                      >
                        {tabs.map(({ key, label }) => (
                          <NavItem key={key}>
                            <NavLink eventKey={key}>{label}</NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="py-3">
                  <div className="d-flex flex-column gap-2">
                    <h5 className="text-muted mb-2">STEP 1</h5>
                    <div>Download the free and full-featured VPN client to connect to your Simbase OpenVPN server.</div>
                    <div>
                      <Button className="" href={getOpenVpnDownloadLink()} target="_blank">
                        Download
                      </Button>
                    </div>
                    <div>
                      <Button className="p-0" variant="link" href={getReadInstallationGuideLink()} target="_blank">
                        Read the installation guide
                      </Button>
                    </div>
                    <hr className="my-3" />
                    <h5 className="text-muted mb-2">STEP 2</h5>
                    <div>Make sure you have downloaded one of the three available .OVPN files above.</div>
                    <hr className="my-3" />
                    <h5 className="text-muted mb-2">STEP 3</h5>
                    <div>
                      After successfully downloading the VPN Connect client, unzip your .OVPN file. Open the client and
                      click the 'plus' in the lower right corner. Add your .OVPN file and connect after import.
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpenVpn;
