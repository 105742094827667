import { BaseDropdown } from '@components/Atoms/BaseDropdown/BaseDropdown';
import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { LoadingPlaceholder } from '@components/Atoms/LoadingPlaceholder/LoadingPlaceholder';
import { ModalV2 } from '@components/Atoms/ModalV2/ModalV2';
import { CURRENCIES } from '@core/constants';
import { MenuItem } from '@headlessui/react';
import { useSignout } from '@modules/auth/signout';
import { TransparentInput } from '@modules/dashboard/simCard';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Currency, Invitation } from 'src/types/types';

type OrganizitionModalProps = {
  invitations: Invitation[];
  open: boolean;
  closeModal: () => void;
  handleCreateOrganization: (companyName: string, currency: string) => void;
  handleJoinOrganization: (organization: Invitation) => void;
  processing: boolean;
  currency?: string;
};

export const OrganizationModal = ({
  invitations,
  open,
  currency,
  handleCreateOrganization,
  handleJoinOrganization,
  processing,
}: OrganizitionModalProps) => {
  const { t } = useTranslation();
  const { forceSignout } = useSignout();
  const organizationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      organizationRef.current?.focus();
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  const [organizationName, setOrganizationName] = useState<string>('');
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(CURRENCIES[0]);
  const [createAccount, setCreateAccount] = useState<boolean>(false);

  useEffect(() => {
    if (!currency || (currency !== 'EUR' && currency !== 'CAD' && currency !== 'AUD' && currency !== 'GBP'))
      setSelectedCurrency(CURRENCIES[0]);
    if (currency === 'USD') setSelectedCurrency(CURRENCIES[0]);
    if (currency === 'CAD') setSelectedCurrency(CURRENCIES[2]);
    if (currency === 'AUD') setSelectedCurrency(CURRENCIES[3]);
    if (currency === 'GBP') setSelectedCurrency(CURRENCIES[4]);
    if (currency === 'EUR') setSelectedCurrency(CURRENCIES[1]);
  }, [currency]);

  const menuItems = (_) => {
    return (
      <>
        {CURRENCIES.map((option, index) => (
          <MenuItem
            key={index}
            as="div"
            onClick={() => {
              setSelectedCurrency(option);
              setTimeout(() => {
                document.getElementById('submitButton')?.focus();
              }, 0);
            }}
            className="tw-w-full"
          >
            {({ active }) => (
              <div
                className={`tw-flex tw-w-full tw-items-center tw-gap-2.5 tw-rounded tw-p-1 ${active ? 'tw-bg-sb-blue-grey-50' : 'tw-bg-white hover:tw-bg-sb-blue-grey-50'}`}
                role="button"
              >
                <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded tw-bg-sb-blue-300">
                  <Icon name={option?.iso} />
                </div>
                <span className="tw-text-base tw-text-sb-blue-700">{option?.iso}</span>
              </div>
            )}
          </MenuItem>
        ))}
      </>
    );
  };

  const renderButton = (open) =>
    selectedCurrency ? (
      <div
        className={`tw-flex !tw-w-[110px] tw-items-center tw-justify-between tw-rounded tw-px-2 tw-py-1 ${open ? '!tw-border-transparent tw-bg-white' : ''}`}
      >
        <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded tw-bg-sb-blue-300">
          <Icon name={selectedCurrency?.iso} />
        </div>
        <span className="tw-text-base tw-text-sb-blue-700">{selectedCurrency?.iso}</span>
        <div className="tw-flex tw-items-center tw-justify-center">
          <Icon name={open ? 'chevron_up' : 'chevron_down'} />
        </div>
      </div>
    ) : (
      <LoadingPlaceholder className="tw-flex tw-h-[42px] tw-w-[110px] tw-items-center tw-justify-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 tw-px-1">
        <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
        <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-slate-200"></div>
      </LoadingPlaceholder>
    );

  return (
    <ModalV2
      modalOpen={open}
      closeModal={() => {}}
      title={
        invitations.length === 0 || createAccount
          ? t('signup.createYourOrganization')
          : invitations.length === 1
            ? t('signup.youHaveBeenInvited')
            : t('signup.youHaveBeenInvitedToMultipleOrganizations')
      }
      dialogPanelClassName={`${invitations.length > 1 && !createAccount ? 'tw-max-w-[584px]' : 'tw-w-full xs:tw-max-w-[550px] tw-flex-grow'}`}
      titleClasses={`${invitations.length > 1 ? '' : 'xs:tw-whitespace-nowrap'}`}
      bottomComponent={() => (
        <Button
          variant="borderless"
          size="xl"
          className="!tw-inline-block !tw-w-auto tw-max-w-max tw-flex-shrink-0 tw-self-center tw-text-sb-blue-300"
          onClick={() => forceSignout()}
        >
          {t('Logout')}
        </Button>
      )}
    >
      <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-5">
        {(invitations.length === 0 || createAccount) && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (organizationName || !processing) handleCreateOrganization(organizationName, selectedCurrency.iso);
            }}
            className="tw-flex tw-w-full tw-flex-col tw-gap-5 tw-pt-11"
          >
            <div className="tw-flex tw-gap-5">
              <TransparentInput
                placeholder={t('signup.organization.organizationName')}
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                className="focus:tw-shadow-input tw-h-[42px] tw-rounded-md tw-border-sb-blue-grey-300 !tw-bg-sb-blue-grey-25"
                inputRef={organizationRef}
              />
              <div>
                <BaseDropdown
                  menuItems={menuItems}
                  children={(open) => renderButton(open)}
                  customMenuItemsClassName="!tw-overflow-hidden !tw-p-1 tw-w-28"
                  menuButtonClassName="tw-border !tw-rounded-md focus:tw-shadow-input tw-h-[42px] tw-w-28 !tw-border-solid !tw-border-sb-blue-grey-300 tw-bg-white   focus:!tw-border-sb-blue-300 !tw-bg-sb-blue-grey-25"
                />
              </div>
            </div>
            <Button size="large" type="submit" disabled={!organizationName || processing} id="submitButton">
              {t('Create')}
            </Button>
            {invitations.length > 0 && (
              <Button
                variant="stripped"
                className="!tw-pt-6 tw-text-lg tw-text-sb-blue-300"
                onClick={() => setCreateAccount(false)}
              >
                {t('signup.joinAnOrganization')}
              </Button>
            )}
          </form>
        )}
        {invitations.length === 1 && !createAccount && (
          <div className="tw-flex tw-w-full tw-flex-col tw-gap-11 tw-pt-5">
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-11">
              <span className="tw-text-lg">
                <Trans>{t('signup.invitedBy', { invitedBy: invitations[0].invited_by })}</Trans>
              </span>
              <div className="tw-flex tw-w-full tw-flex-col tw-gap-6">
                <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-px-6 tw-py-4">
                  <span className="tw-text-xl tw-font-semibold tw-text-sb-blue-500">{invitations[0].company_name}</span>
                </div>
                <div className="tw-w-full tw-shrink-0">
                  <Button
                    size="large"
                    disabled={processing}
                    onClick={() => handleJoinOrganization(invitations[0])}
                    className="tw-w-full tw-capitalize"
                  >
                    {t('joinOrganization')}
                  </Button>
                </div>
              </div>
              <span className="tw-inline-block tw-text-center tw-text-lg">
                <Trans
                  i18nKey="signup.dontWantToJoin"
                  components={{
                    customButton: (
                      <button
                        className="!tw-m-0 tw-border-none tw-bg-transparent !tw-p-0 tw-text-inherit tw-text-sb-blue-300"
                        onClick={() => setCreateAccount(true)}
                      />
                    ),
                  }}
                />
              </span>
            </div>
          </div>
        )}
        {invitations.length > 1 && !createAccount && (
          <div className="tw-flex tw-flex-col tw-gap-11 tw-pt-5">
            <span className="tw-text-center tw-text-lg tw-text-sb-blue-500">
              {t('signup.pleaseSelectOrganization')}
            </span>
            <div className="tw-flex tw-flex-col tw-gap-5">
              {invitations.map((organization) => (
                <div className="tw-flex tw-w-full tw-flex-col tw-gap-3.5">
                  <span>
                    <Trans>{t('signup.invitedByMultiple', { invitedBy: organization.invited_by })}</Trans>
                  </span>
                  <div className="tw-flex tw-gap-5">
                    <div className="tw-flex tw-h-11 tw-w-full tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-px-6">
                      <span className="tw-text-xl tw-font-semibold tw-text-sb-blue-500">
                        {organization.company_name}
                      </span>
                    </div>
                    <Button
                      size="large"
                      className="!tw-h-11"
                      disabled={processing}
                      onClick={() => handleJoinOrganization(organization)}
                    >
                      {t('Join')}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <span className="tw-inline-block tw-text-center tw-text-lg">
              <Trans
                i18nKey="signup.dontWantToJoin"
                components={{
                  customButton: (
                    <button
                      className="!tw-m-0 tw-border-none tw-bg-transparent !tw-p-0 tw-text-inherit tw-text-sb-blue-300"
                      onClick={() => setCreateAccount(true)}
                    />
                  ),
                }}
              />
            </span>
          </div>
        )}
      </div>
    </ModalV2>
  );
};
