import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type ModalV2Props = {
  modalOpen: boolean;
  closeModal: () => void;
  title: string;
  children?: ReactNode;
  className?: string;
  titleClasses?: string;
  bottomComponent?: () => ReactNode;
  dialogPanelClassName?: string;
};

export const ModalV2: FC<ModalV2Props> = ({
  title,
  modalOpen,
  closeModal,
  children,
  className = '',
  titleClasses = '',
  bottomComponent,
  dialogPanelClassName = '',
}) => {
  const modalContent = (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog as="div" className="tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto" onClose={closeModal}>
        <div
          className="tw-bg-background-blur tw-fixed tw-inset-0 tw-overflow-visible tw-backdrop-blur-md"
          aria-hidden="true"
        />
        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
          <TransitionChild
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <DialogPanel
              className={`tw-relative tw-flex tw-flex-col tw-gap-4 ${dialogPanelClassName}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={`xs:tw-px-8 tw-relative tw-rounded-xl tw-bg-white tw-px-4 tw-py-11 tw-backdrop-blur-2xl sm:tw-px-16 ${className}`}
              >
                <div className="tw-flex tw-justify-center tw-text-center tw-align-middle">
                  <DialogTitle
                    as="span"
                    className={`tw-font-cal-sans tw-text-4xl tw-font-semibold tw-text-sb-blue-300 ${titleClasses}`}
                  >
                    {title}
                  </DialogTitle>
                </div>
                {children}
              </div>
              {!!bottomComponent && bottomComponent()}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
  return createPortal(modalContent, document.body);
};
